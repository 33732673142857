.l-header {
  width: 100%;
  padding: 5px 0;
  background-color: $blackPale;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9900;

  @include sp {
    height: 7vh;
    padding: 1rem 0;
  }

  &_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_logo {
    width: 120px;

    @include sp {
      width: 30%;
    }

    &Item {
      display: block;
      position: relative;
      z-index: 1;

      @include pc {
        transition: opacity $anime;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &_box {
    display: flex;
    justify-content: flex-end;

    @include pc {
      flex: 1;
    }
  }

  &Nav {
    width: 100%;
    display: flex;

    @include pc {
      flex: 1;
    }

    @include sp {
      width: 100%;
      padding: 1rem 0;
      background-color: $blackPale;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9990;
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include sp {
        justify-content: space-around;
      }
    }

    &_list {
      width: 16px;
      margin: 0 8% 0 0;

      @include sp {
        width: 1.5rem;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--pin {
        @extend .l-headerNav_list;
        width: 14px;

        @include sp {
          width: 1.2rem;
        }
      }
    }

    &_item {
      width: 100%;
      display: block;
      cursor: pointer;
      position: relative;
      z-index: 1;

      @include pc {
        transition: opacity $anime;

        &:hover {
          opacity: 0.7;
        }
      }

      @include sp {
      }
    }

    &_num {
      min-width: 18px;
      padding: 0 4px;
      display: inline-block;
      text-align: center;
      background-color: $redPale;
      border-radius: 100px;
      position: absolute;
      top: -9px;
      right: -12px;
      z-index: 2;

      @include sp {
        min-width: auto;
        padding: 0 0.5rem;
        top: -0.5rem;
        right: -1.2rem;
        border-radius: 50px;
      }

      &--inner {
        @extend .l-headerNav_num;

        @include sp {
          min-width: auto;
          margin: 0 0 0 1.5rem;
          padding: 0 0.8rem;
          position: static;
        }
      }
    }
  }

  &Intro {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 1;

    @include sp {
      width: 90vw;
      height: 100vh;
      padding: 0 0 0 2rem;
      display: block;
      background-color: $blackPale;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      opacity: 0;
      pointer-events: none;

      &.is-view {
        opacity: 1;
        pointer-events: auto;
        transform: translateX(100%);
        overflow: hidden;
      }
    }

    &.is-active {
      @include pc {
        .l-headerIntro_box {
          opacity: 1 !important;
          pointer-events: auto;
        }
      }
    }

    &_item {
      display: inline-block;
      position: relative;
      z-index: 1;
      cursor: pointer;

      @include pc {
        transition: opacity $anime;

        &:hover {
          opacity: 0.7;
        }
      }

      @include sp {
        width: 100%;
      }
    }

    &_box {
      width: 100%;
      min-width: 100px;
      padding: 5px 0 0;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      position: absolute;
      // top: calc(100% + 5px);
      top: 100%;
      right: 0;
      z-index: 2;
      transition: opacity $anime;
      opacity: 0;
      pointer-events: none;
    }

    &_cont {
      &Item {
        width: 100%;
        padding: 8px 0;
        display: block;
        text-align: center;
        color: $white;
        background-color: $blackPale;

        @include pc {
          transition: background-color $anime;

          &:hover {
            background-color: $orange;
          }
        }
      }
    }
  }

  &Inner {
    @include pc {
      display: none;
    }

    @include sp {
      width: 100%;

      &_cont {
        width: 100%;
      }

      &_list {
        @include sp {
          border-top: 1px solid $white;
        }
      }

      &_item {
        @include sp {
          width: 100%;
          padding: 1.2rem;
          display: block;
          font-size: 1.2rem;
          color: $white;
        }
      }
    }
  }

  &Hamburger {
    @include pc {
      display: none;
    }

    @include sp {
      width: 2.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      right: 0.9rem;
      z-index: 3;
      transform: translateY(-50%);

      &.is-active {
        .l-headerHamburger {
          &_line {
            top: 50%;
            &--top {
              transform: translate(-50%, -50%) rotate(-45deg);
            }

            &--mid {
              opacity: 0;
            }

            &--btm {
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }
        }
      }

      &_line {
        width: 100%;
        height: 2px;
        background-color: $white;
        position: absolute;
        left: 50%;
        z-index: 4;

        &--top {
          @extend .l-headerHamburger_line;
          top: 0;
          transform: translate(-50%, 0);
          transition: transform $anime;
        }

        &--mid {
          @extend .l-headerHamburger_line;
          width: 75%;
          top: 50%;
          right: 0;
          left: auto;
          transition: opacity $anime;
        }

        &--btm {
          @extend .l-headerHamburger_line;
          top: 100%;
          transform: translate(-50%, 0);
          transition: transform $anime;
        }
      }
    }
  }
}
