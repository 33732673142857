@charset "UTF-8";

/**** transition ****/
$anime: 0.3s ease;

/**** color ****/
// white
$white: #fff;

// black
$black: #3e3a39;
$blackPale: #595757;

// gray
$grayPale: #f7f8f8;
$grayPale02: #dcdddd;
$grayPale03: #efefef;
$grayDeep: #9FA0A0;
$grayDeep02: #878788;

// orange
$orange: #ec6b2e;
$orange02: #EA5514;
$orange03: #d16a36;
$orangePale: #fdeadd;
$orangePale02: #FDF1EC;

// yellow
$yellow: #f8b500;
$yellowPale: #FFFAEB;
$yellowDeep: #e7b421;

// red
$redPale: #ec6b2e;

// aqua
$aqua: #b0dff8;

// green
$green: #009fa8;
$greenPale: #edf6f7;

// pink
$pink: #e85298;
$pinkPale: #faf2f7;

// blue
$blue: #4c8dcb;
$bluePale: #f1f4fa;

/**** grad ****/
$gradYellow: linear-gradient(45deg, rgba(248, 181, 0, 1) 0%, rgba(255, 231, 63, 1) 100%);

$gradWhite: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

/* keyframes */
@-webkit-keyframes handAction {
  0% {
    transform: rotate(0deg) scale(1);
    transform-origin: left bottom;
  }

  50% {
    transform: rotate(-30deg) scale(1.1);
    transform-origin: left bottom;
  }

  100% {
    transform: rotate(0deg) scale(1);
    transform-origin: left bottom;
  }
}
@keyframes handAction {
  0% {
    transform: rotate(0deg) scale(1);
    transform-origin: left bottom;
  }

  50% {
    transform: rotate(-30deg) scale(1.1);
    transform-origin: left bottom;
  }

  100% {
    transform: rotate(0deg) scale(1);
    transform-origin: left bottom;
  }
}
