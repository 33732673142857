@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin opacity() {
  width: 100%;
  height: 100%;
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity $anime;
}

@mixin hoverLine() {
  width: 0;
  height: 1px;
  top: 100%;
  position: absolute;
  left: 0;
  transition: width $anime;
}
