.c-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include pc {
    &:after {
      content: "";
      width: 32%;
      display: block;
    }
  }

  @include sp {
    display: block;
  }

  &_list {
    width: 32%;
    background-color: $white;
    position: relative;
    z-index: 1;
    border-radius: 20px;

    @include pc {
      max-width: 316px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      &:nth-child(n + 4) {
        margin: 88px 0 0;
      }
    }

    @include sp {
      width: 100%;

      & + & {
        margin: 4rem 0 0;
      }
    }
  }

  &_cont {
    width: 100%;
    padding: 6%;

    @include sp {
      padding: 2rem 1.5rem;
    }
  }

  &_date {
    position: absolute;
    right: 0;
    bottom: 101%;
    z-index: 2;
  }

  &_config {
    width: 100%;
    margin: 0 0 12px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 5;

    @include sp {
      margin: 0 0 1rem;
    }

    &Cont {
      width: 30px;
      height: 30px;
      margin: 0 -10px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      z-index: 1;

      @include pc {
        transition: background-color $anime;

        &:hover {
          background-color: $grayPale;
        }
      }

      &.is-active {
        background-color: $grayPale;
      }

      &Item {
        width: 5px;
        height: 5px;
        background-color: $black;
        border-radius: 50%;

        & + & {
          margin: 0 0 0 2px;
        }
      }
    }

    &Pop {
      width: 72px;
      text-align: center;
      border-radius: 10px;
      overflow: hidden;
      position: absolute;
      top: 80%;
      right: -30px;
      z-index: 2;
      transition: opacity $anime;
      opacity: 0;
      pointer-events: none;

      @include sp {
        right: -10px;
        border: 2px solid $grayPale;
      }

      &.is-view {
        opacity: 1;
        pointer-events: auto;
      }

      &Item {
        width: 100%;
        padding: 14px 0;
        display: block;
        font-size: 12px;
        font-weight: bold;
        color: $black;
        background-color: $white;
        cursor: pointer;

        @include pc {
          transition: background-color $anime;

          &:hover {
            background-color: $grayPale;
          }
        }
      }
    }
  }

  &_job {
    margin: 0 0 20px;
  }

  &_intro {
    margin: 0 0 20px;
  }

  &_tag {
    margin: 0 0 8px;

    li {
      cursor: pointer !important;
    }
  }

  &_person {
    width: 100%;
    height: 15px;
    margin: 0 0 28px;
    position: relative;
    z-index: 1;

    @include sp {
      height: 2.2rem;
      margin: 0 0 2rem;
    }

    &:after {
      content: "";
      width: 100%;
      height: 28px;
      background-color: $white;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;

      @include sp {
        height: 2.2rem;
      }
    }
  }

  &_img {
    width: 100%;
    margin: 0 0 16px;
    position: relative;
    z-index: 1;
  }

  &_pic {
    width: 100%;
    height: 200px;
    background-color: $grayPale02;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &Src {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;

      &--detail {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        cursor: pointer;

        @include pc {
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          transition: opacity $anime;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  &_search {
    text-align: center;
  }

  &_pin {
    width: 45px;
    height: 45px;
    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 2;

    @include sp {
      width: 4rem;
      height: 4rem;
    }

    &Ico {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $orangePale;
      border-radius: 50%;
      cursor: pointer;
      overflow: hidden;

      @include pc {
        transition: background-color $anime;

        &:hover {
          background-color: $orange;
        }
      }

      &.is-active {
        background-color: $orange;
      }

      img {
        width: 40%;

        @include sp {
          width: 1.7rem;
        }
      }
    }
  }

  &_comment {
    width: 100%;
    height: 2.5em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @include sp {
      height: 3.6rem;
    }
  }

  &_more {
    $this: &;
    width: 100%;
    margin: 20px 0;
    text-align: center;

    @include sp {
      margin: 2rem 0;
    }

    &--btm {
      @extend #{$this};
      margin: 20px 0 28px;

      @include sp {
        margin: 2rem 0 2.5rem;
      }
    }
  }

  &_point {
    width: 100%;
    margin-bottom: 24px;
  }

  &_ico {
    margin: 0 0 8px;

    @include sp {
      margin: 0 0 1rem;
    }
  }

  &_box {
    width: 100%;
  }

  &_btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &Item {
      & + & {
        margin: 0 0 0 12px;

        @include sp {
          margin: 0 0 0 1.5rem;
        }
      }
    }
  }

  &_good {
    border-radius: 0 0 20px 20px;
    overflow: hidden;
  }

  &Detail {
    width: 100%;
    background-color: $white;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &_date {
      position: absolute;
      right: 0;
      bottom: calc(100% + 6px);
      z-index: 2;

      @include sp {
        bottom: calc(99% + 6px);
      }
    }

    &_cont {
      width: 100%;
      padding: 10%;

      @include sp {
        padding: 3rem 2rem;
      }
    }

    &_job {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include sp {
        align-items: center;
      }

      &Box {
        flex: 1;
      }

      &Pin {
        width: 50px;
        height: 50px;
        margin: 0 0 0 60px;

        @include sp {
          width: 3.5rem;
          height: 3.5rem;
          margin: 0 0 0 1rem;
        }
      }
    }

    &_intro {
      width: 100%;
      margin: 52px 0 60px;

      @include sp {
        margin: 2rem 0;
      }

      &Cont {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @include sp {
          display: block;
        }
      }

      &Box {
        &:first-child {
          @include pc {
            flex: 1;
          }
        }

        &:last-child {
          width: 232px;
          margin: 0 0 0 40px;

          @include sp {
            width: 100%;
            margin: 1rem 0 0;
          }
        }
      }

      &Inner {
        margin: 12px 0;
        display: flex;
        justify-content: flex-end;
        padding: 0 0 0 52px;

        @include sp {
          margin: 0.5rem 0;
          padding: 0;
          justify-content: flex-start;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_cat {
      width: 100%;
      margin-top: 12px;

      @include sp {
        margin-top: 1.5rem;
      }
    }

    &_wrap {
      width: 100%;
      margin: 0 auto;

      @include pc {
        max-width: 820px;
      }

      & + & {
        margin-top: 40px;

        @include sp {
          margin-top: 2rem;
        }
      }
    }

    &_img {
      width: 100%;
      border-radius: 16px;
      overflow: hidden;
    }

    &_pic {
      width: 100%;
      height: 450px;

      @include sp {
        height: 20rem;
      }
    }

    &_thumbs {
      width: 100%;
      height: 110px;

      @include sp {
        height: 5rem;
      }
    }

    &_body {
      width: 100%;
      margin: 48px 0 72px;

      @include sp {
        margin: 2rem 0 4rem;
      }

      &Box {
        & + & {
          margin: 20px 0 0;
        }
      }
    }

    &_comment {
      width: 100%;
      margin: 16px 0 32px;
      padding: 0 60px;

      @include sp {
        margin: 2rem 0;
        padding: 0;
      }
    }

    &_ico {
      width: 100%;
      margin: 72px 0 0;

      @include sp {
        margin: 3rem 0 0;
      }
    }

    &_scroll {
      width: 100%;
      height: 180px;
      margin: 24px 0 28px;
      padding: 28px 32px 28px 0;
      position: relative;

      &Item {
        & + & {
          margin: 16px 0 0;
        }
      }
    }

    &_close {
      margin: 52px 0 120px;

      @include sp {
        margin: 4rem 0 6rem;
      }
    }

    &_release {
      width: 110px;
      display: flex;
      align-items: center;
      font-size: 8px;
      font-weight: bold;
      border-radius: 100px;
      border: 1px solid $black;
      overflow: hidden;

      @include sp {
        width: 40%;
        font-size: 0.9rem;
      }

      &Head {
        width: 50px;
        padding: 4px 0 4px 4px;
        text-align: center;
        color: $white;
        background-color: $black;

        @include sp {
          width: 45%;
          padding: 0.4rem 0 0.4rem 0.4rem;
        }
      }

      &Body {
        flex: 1;
        padding: 4px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $black;
        background-color: $white;

        @include sp {
          padding: 0.4rem 0;
        }
      }

      &Num {
        display: flex;
        align-items: flex-end;

        & + & {
          &:before {
            content: ".";
            font-size: inherit;
            color: inherit;
          }
        }
      }
    }
  }
}
