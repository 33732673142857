.c-btn {
  width: 220px;
  margin: 0 auto;
  padding: 16px 0;
  box-shadow: none;
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.17em;
  text-align: center;
  color: $orange;
  background-color: $grayPale;
  border: none;
  border-radius: 100px;
  -webkit-appearance: none;
  cursor: pointer;

  @include pc {
    transition: color $anime, background-color $anime;

    &:hover {
      color: $white;
      background-color: $orange;
    }
  }

  @include tab {
    font-size: 14px;
  }

  @include sp {
    width: 65%;
    padding: 1.5rem 0;
    font-size: 1.2rem;
    color: $white;
    background-color: $orange;
  }

  &_cancel {
    width: 80px;
    padding: 12px 0;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    background: $white;
    border-radius: 100px;
    border: none;
    -webkit-appearance: none;
    cursor: pointer;

    @include pc {
      transition: background-color $anime;

      &:hover {
        background-color: $grayPale;
      }
    }

    @include tab {
      font-size: 10px;
    }

    @include sp {
      width: 8rem;
      padding: 1rem 0;
      font-size: 1rem;
      background-color: $grayPale;
    }

    &--large {
      @extend .c-btn_cancel;
      width: 140px;
      padding: 20px 0;
      font-size: 17px;
    }
  }

  &_post {
    @extend .c-btn_cancel;
    color: $orange;

    @include pc {
      transition: color $anime, background-color $anime;
      outline: none;

      &:hover {
        color: $white;
        background-color: $orange;
      }
    }

    @include sp {
      color: $white;
      background-color: $orange;
    }

    &--large {
      @extend .c-btn_post;
      width: 140px;
      padding: 20px 0;
      font-size: 17px;
    }
  }

  &_good {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grayPale;
    border: none;
    -webkit-appearance: none;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @include pc {
      transition: background-color $anime;

      &:hover {
        background-color: $orange;

        // &:after {
        //   background: url('../img/common/text_nice_job_on.svg') no-repeat center;
        //   background-size: contain;
        // }

        .c-btn {
          &_good {
            &Text {
              .goodText {
                fill: $white;
              }
            }

            &Hand {
              .goodHand {
                stroke: $white;
              }
            }
          }
        }
      }
    }

    // &:after {
    //   content: '';
    //   width: 205px;
    //   height: 30px;
    //   background: url('../img/common/text_nice_job.svg') no-repeat center;
    //   background-size: contain;
    //   position: absolute;
    //   top: calc(50% - 4px);
    //   left: 50%;
    //   z-index: 2;
    //   transform: translate(-50%, -50%);
    //   -webkit-backface-visibility: hidden;
    //   backface-visibility: hidden;
    // }

    &.is-active {
      background-color: $orange;

      &:after {
        background: url('../img/common/text_nice_job_on.svg') no-repeat center;
        background-size: contain;
      }

      .c-btn {
        &_good {
          &Text {
            .goodText {
              fill: $white;
            }
          }

          &Hand {
            animation: handAction 0.3s ease forwards;

            .goodHand {
              stroke: $white;
            }
          }
        }
      }
    }

    &--large {
      @extend .c-btn_good;
      height: 150px;

      @include sp {
        height: 7rem;
      }

      &:after {
        width: 468px;
        height: 68px;

        @include sp {
          max-width: 80%;
          width: 100%;
          height: 2.5rem;
        }
      }
    }

    &Item {
      width: 205px;
      height: 30px;
      margin: -4px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &Text {
      width: 83%;

      @include pc {
        max-width: 170px;
      }

      svg {
        .goodText {
          @include pc {
            transition: fill $anime;
          }
        }
      }
    }

    &Hand {
      width: 12%;
      transform-origin: left bottom;

      @include pc {
        max-width: 24px;
      }

      svg {
        .goodHand {
          @include pc {
            transition: stroke $anime;
          }
        }
      }
    }
  }
}
