@charset "UTF-8";


/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

li {
  list-style: none;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
img {
  vertical-align: bottom;
}

/**** transition ****/
/**** color ****/
/**** grad ****/
/* keyframes */
@-webkit-keyframes handAction {
  0% {
    transform: rotate(0deg) scale(1);
    transform-origin: left bottom;
  }
  50% {
    transform: rotate(-30deg) scale(1.1);
    transform-origin: left bottom;
  }
  100% {
    transform: rotate(0deg) scale(1);
    transform-origin: left bottom;
  }
}

@keyframes handAction {
  0% {
    transform: rotate(0deg) scale(1);
    transform-origin: left bottom;
  }
  50% {
    transform: rotate(-30deg) scale(1.1);
    transform-origin: left bottom;
  }
  100% {
    transform: rotate(0deg) scale(1);
    transform-origin: left bottom;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: calc(100vw / 32);
  }
}

body {
  min-height: 100vh;
  font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', 'メイリオ', sans-serif;
  color: #3e3a39;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  body {
    transition: transform 0.3s ease;
  }
}

body.is-fixed {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  body.is-move {
    transform: translateX(-90%);
    overflow: hidden;
  }
}

main {
  width: 100%;
  height: 100%;
  padding: 130px 0;
  display: block;
}

@media screen and (max-width: 767px) {
  main {
    padding: 9rem 0 7rem;
  }
}

main.is-login {
  padding: 0;
}

@media screen and (max-width: 767px) {
  main.is-login {
    padding: 0;
  }
}

img {
  width: 100%;
  display: block;
}

.is-pop {
  position: relative !important;
  z-index: 10 !important;
}

@media screen and (min-width: 768px) {
  .pc {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

.l-header {
  width: 100%;
  padding: 5px 0;
  background-color: #595757;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9900;
}

@media screen and (max-width: 767px) {
  .l-header {
    height: 7vh;
    padding: 1rem 0;
  }
}

.l-header_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l-header_logo {
  width: 120px;
}

@media screen and (max-width: 767px) {
  .l-header_logo {
    width: 30%;
  }
}

.l-header_logoItem {
  display: block;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .l-header_logoItem {
    transition: opacity 0.3s ease;
  }
  .l-header_logoItem:hover {
    opacity: 0.7;
  }
}

.l-header_box {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 768px) {
  .l-header_box {
    flex: 1;
  }
}

.l-headerNav {
  width: 100%;
  display: flex;
}

@media screen and (min-width: 768px) {
  .l-headerNav {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .l-headerNav {
    width: 100%;
    padding: 1rem 0;
    background-color: #595757;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9990;
  }
}

.l-headerNav_cont {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .l-headerNav_cont {
    justify-content: space-around;
  }
}

.l-headerNav_list, .l-headerNav_list--pin {
  width: 16px;
  margin: 0 8% 0 0;
}

@media screen and (max-width: 767px) {
  .l-headerNav_list, .l-headerNav_list--pin {
    width: 1.5rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.l-headerNav_list--pin {
  width: 14px;
}

@media screen and (max-width: 767px) {
  .l-headerNav_list--pin {
    width: 1.2rem;
  }
}

.l-headerNav_item {
  width: 100%;
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .l-headerNav_item {
    transition: opacity 0.3s ease;
  }
  .l-headerNav_item:hover {
    opacity: 0.7;
  }
}

.l-headerNav_num, .l-headerNav_num--inner {
  min-width: 18px;
  padding: 0 4px;
  display: inline-block;
  text-align: center;
  background-color: #ec6b2e;
  border-radius: 100px;
  position: absolute;
  top: -9px;
  right: -12px;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .l-headerNav_num, .l-headerNav_num--inner {
    min-width: auto;
    padding: 0 0.5rem;
    top: -0.5rem;
    right: -1.2rem;
    border-radius: 50px;
  }
}

@media screen and (max-width: 767px) {
  .l-headerNav_num--inner {
    min-width: auto;
    margin: 0 0 0 1.5rem;
    padding: 0 0.8rem;
    position: static;
  }
}

.l-headerIntro {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .l-headerIntro {
    width: 90vw;
    height: 100vh;
    padding: 0 0 0 2rem;
    display: block;
    background-color: #595757;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
  .l-headerIntro.is-view {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(100%);
    overflow: hidden;
  }
}

@media screen and (min-width: 768px) {
  .l-headerIntro.is-active .l-headerIntro_box {
    opacity: 1 !important;
    pointer-events: auto;
  }
}

.l-headerIntro_item {
  display: inline-block;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .l-headerIntro_item {
    transition: opacity 0.3s ease;
  }
  .l-headerIntro_item:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 767px) {
  .l-headerIntro_item {
    width: 100%;
  }
}

.l-headerIntro_box {
  width: 100%;
  min-width: 100px;
  padding: 5px 0 0;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
}

.l-headerIntro_contItem {
  width: 100%;
  padding: 8px 0;
  display: block;
  text-align: center;
  color: #fff;
  background-color: #595757;
}

@media screen and (min-width: 768px) {
  .l-headerIntro_contItem {
    transition: background-color 0.3s ease;
  }
  .l-headerIntro_contItem:hover {
    background-color: #ec6b2e;
  }
}

@media screen and (min-width: 768px) {
  .l-headerInner {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .l-headerInner {
    width: 100%;
  }
  .l-headerInner_cont {
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .l-headerInner_list {
    border-top: 1px solid #fff;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .l-headerInner_item {
    width: 100%;
    padding: 1.2rem;
    display: block;
    font-size: 1.2rem;
    color: #fff;
  }
}

@media screen and (min-width: 768px) {
  .l-headerHamburger {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .l-headerHamburger {
    width: 2.5rem;
    height: 1.5rem;
    position: absolute;
    top: 50%;
    right: 0.9rem;
    z-index: 3;
    transform: translateY(-50%);
  }
  .l-headerHamburger.is-active .l-headerHamburger_line, .l-headerHamburger.is-active .l-headerHamburger_line--top, .l-headerHamburger.is-active .l-headerHamburger_line--mid, .l-headerHamburger.is-active .l-headerHamburger_line--btm {
    top: 50%;
  }
  .l-headerHamburger.is-active .l-headerHamburger_line--top {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  .l-headerHamburger.is-active .l-headerHamburger_line--mid {
    opacity: 0;
  }
  .l-headerHamburger.is-active .l-headerHamburger_line--btm {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .l-headerHamburger_line, .l-headerHamburger_line--top, .l-headerHamburger_line--mid, .l-headerHamburger_line--btm {
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    z-index: 4;
  }
  .l-headerHamburger_line--top {
    top: 0;
    transform: translate(-50%, 0);
    transition: transform 0.3s ease;
  }
  .l-headerHamburger_line--mid {
    width: 75%;
    top: 50%;
    right: 0;
    left: auto;
    transition: opacity 0.3s ease;
  }
  .l-headerHamburger_line--btm {
    top: 100%;
    transform: translate(-50%, 0);
    transition: transform 0.3s ease;
  }
}

.l-section--profile {
  background-color: #f8b500;
}

.l-wrap {
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .l-wrap {
    max-width: 1040px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-wrap {
    width: 95%;
  }
}

.c-bgGrad--yellow {
  position: relative;
  z-index: 1;
}

.c-bgGrad--yellow:after {
  content: '';
  width: 100vw;
  height: 100vh;
  background: linear-gradient(45deg, #f8b500 0%, #ffe73f 100%);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
}

.c-bgGrad_inner--yellow {
  background: linear-gradient(45deg, #f8b500 0%, #ffe73f 100%);
}

.c-btn {
  width: 220px;
  margin: 0 auto;
  padding: 16px 0;
  box-shadow: none;
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.17em;
  text-align: center;
  color: #ec6b2e;
  background-color: #f7f8f8;
  border: none;
  border-radius: 100px;
  -webkit-appearance: none;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-btn {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .c-btn:hover {
    color: #fff;
    background-color: #ec6b2e;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .c-btn {
    width: 65%;
    padding: 1.5rem 0;
    font-size: 1.2rem;
    color: #fff;
    background-color: #ec6b2e;
  }
}

.c-btn_cancel, .c-btn_cancel--large, .c-btn_post, .c-btn_post--large {
  width: 80px;
  padding: 12px 0;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  border-radius: 100px;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-btn_cancel, .c-btn_cancel--large, .c-btn_post, .c-btn_post--large {
    transition: background-color 0.3s ease;
  }
  .c-btn_cancel:hover, .c-btn_cancel--large:hover, .c-btn_post:hover, .c-btn_post--large:hover {
    background-color: #f7f8f8;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-btn_cancel, .c-btn_cancel--large, .c-btn_post, .c-btn_post--large {
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .c-btn_cancel, .c-btn_cancel--large, .c-btn_post, .c-btn_post--large {
    width: 8rem;
    padding: 1rem 0;
    font-size: 1rem;
    background-color: #f7f8f8;
  }
}

.c-btn_cancel--large {
  width: 140px;
  padding: 20px 0;
  font-size: 17px;
}

.c-btn_post, .c-btn_post--large {
  color: #ec6b2e;
}

@media screen and (min-width: 768px) {
  .c-btn_post, .c-btn_post--large {
    transition: color 0.3s ease, background-color 0.3s ease;
    outline: none;
  }
  .c-btn_post:hover, .c-btn_post--large:hover {
    color: #fff;
    background-color: #ec6b2e;
  }
}

@media screen and (max-width: 767px) {
  .c-btn_post, .c-btn_post--large {
    color: #fff;
    background-color: #ec6b2e;
  }
}

.c-btn_post--large {
  width: 140px;
  padding: 20px 0;
  font-size: 17px;
}

.c-btn_good, .c-btn_good--large {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8f8;
  border: none;
  -webkit-appearance: none;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.c-btn_good:focus, .c-btn_good--large:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .c-btn_good, .c-btn_good--large {
    transition: background-color 0.3s ease;
  }
  .c-btn_good:hover, .c-btn_good--large:hover {
    background-color: #ec6b2e;
  }
  .c-btn_good:hover .c-btn_goodText .goodText, .c-btn_good--large:hover .c-btn_goodText .goodText {
    fill: #fff;
  }
  .c-btn_good:hover .c-btn_goodHand .goodHand, .c-btn_good--large:hover .c-btn_goodHand .goodHand {
    stroke: #fff;
  }
}

.c-btn_good.is-active, .is-active.c-btn_good--large {
  background-color: #ec6b2e;
}

.c-btn_good.is-active:after, .is-active.c-btn_good--large:after {
  background: url("../img/common/text_nice_job_on.svg") no-repeat center;
  background-size: contain;
}

.c-btn_good.is-active .c-btn_goodText .goodText, .is-active.c-btn_good--large .c-btn_goodText .goodText {
  fill: #fff;
}

.c-btn_good.is-active .c-btn_goodHand, .is-active.c-btn_good--large .c-btn_goodHand {
  animation: handAction 0.3s ease forwards;
}

.c-btn_good.is-active .c-btn_goodHand .goodHand, .is-active.c-btn_good--large .c-btn_goodHand .goodHand {
  stroke: #fff;
}

.c-btn_good--large {
  height: 150px;
}

@media screen and (max-width: 767px) {
  .c-btn_good--large {
    height: 7rem;
  }
}

.c-btn_good--large:after {
  width: 468px;
  height: 68px;
}

@media screen and (max-width: 767px) {
  .c-btn_good--large:after {
    max-width: 80%;
    width: 100%;
    height: 2.5rem;
  }
}

.c-btn_goodItem {
  width: 205px;
  height: 30px;
  margin: -4px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.c-btn_goodText {
  width: 83%;
}

@media screen and (min-width: 768px) {
  .c-btn_goodText {
    max-width: 170px;
  }
}

@media screen and (min-width: 768px) {
  .c-btn_goodText svg .goodText {
    transition: fill 0.3s ease;
  }
}

.c-btn_goodHand {
  width: 12%;
  transform-origin: left bottom;
}

@media screen and (min-width: 768px) {
  .c-btn_goodHand {
    max-width: 24px;
  }
}

@media screen and (min-width: 768px) {
  .c-btn_goodHand svg .goodHand {
    transition: stroke 0.3s ease;
  }
}

.c-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .c-card:after {
    content: "";
    width: 32%;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .c-card {
    display: block;
  }
}

.c-card_list {
  width: 32%;
  background-color: #fff;
  position: relative;
  z-index: 1;
  border-radius: 20px;
}

@media screen and (min-width: 768px) {
  .c-card_list {
    max-width: 316px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
  .c-card_list:nth-child(n + 4) {
    margin: 88px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .c-card_list {
    width: 100%;
  }
  .c-card_list + .c-card_list {
    margin: 4rem 0 0;
  }
}

.c-card_cont {
  width: 100%;
  padding: 6%;
}

@media screen and (max-width: 767px) {
  .c-card_cont {
    padding: 2rem 1.5rem;
  }
}

.c-card_date {
  position: absolute;
  right: 0;
  bottom: 101%;
  z-index: 2;
}

.c-card_config {
  width: 100%;
  margin: 0 0 12px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 5;
}

@media screen and (max-width: 767px) {
  .c-card_config {
    margin: 0 0 1rem;
  }
}

.c-card_configCont {
  width: 30px;
  height: 30px;
  margin: 0 -10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .c-card_configCont {
    transition: background-color 0.3s ease;
  }
  .c-card_configCont:hover {
    background-color: #f7f8f8;
  }
}

.c-card_configCont.is-active {
  background-color: #f7f8f8;
}

.c-card_configContItem {
  width: 5px;
  height: 5px;
  background-color: #3e3a39;
  border-radius: 50%;
}

.c-card_configContItem + .c-card_configContItem {
  margin: 0 0 0 2px;
}

.c-card_configPop {
  width: 72px;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 80%;
  right: -30px;
  z-index: 2;
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .c-card_configPop {
    right: -10px;
    border: 2px solid #f7f8f8;
  }
}

.c-card_configPop.is-view {
  opacity: 1;
  pointer-events: auto;
}

.c-card_configPopItem {
  width: 100%;
  padding: 14px 0;
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #3e3a39;
  background-color: #fff;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-card_configPopItem {
    transition: background-color 0.3s ease;
  }
  .c-card_configPopItem:hover {
    background-color: #f7f8f8;
  }
}

.c-card_job {
  margin: 0 0 20px;
}

.c-card_intro {
  margin: 0 0 20px;
}

.c-card_tag {
  margin: 0 0 8px;
}

.c-card_tag li {
  cursor: pointer !important;
}

.c-card_person {
  width: 100%;
  height: 15px;
  margin: 0 0 28px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-card_person {
    height: 2.2rem;
    margin: 0 0 2rem;
  }
}

.c-card_person:after {
  content: "";
  width: 100%;
  height: 28px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .c-card_person:after {
    height: 2.2rem;
  }
}

.c-card_img {
  width: 100%;
  margin: 0 0 16px;
  position: relative;
  z-index: 1;
}

.c-card_pic {
  width: 100%;
  height: 200px;
  background-color: #dcdddd;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.c-card_picSrc {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.c-card_picSrc--detail {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-card_picSrc--detail {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: opacity 0.3s ease;
  }
  .c-card_picSrc--detail:hover {
    opacity: 0.7;
  }
}

.c-card_search {
  text-align: center;
}

.c-card_pin {
  width: 45px;
  height: 45px;
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .c-card_pin {
    width: 4rem;
    height: 4rem;
  }
}

.c-card_pinIco {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdeadd;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .c-card_pinIco {
    transition: background-color 0.3s ease;
  }
  .c-card_pinIco:hover {
    background-color: #ec6b2e;
  }
}

.c-card_pinIco.is-active {
  background-color: #ec6b2e;
}

.c-card_pinIco img {
  width: 40%;
}

@media screen and (max-width: 767px) {
  .c-card_pinIco img {
    width: 1.7rem;
  }
}

.c-card_comment {
  width: 100%;
  height: 2.5em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media screen and (max-width: 767px) {
  .c-card_comment {
    height: 3.6rem;
  }
}

.c-card_more, .c-card_more--btm {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-card_more, .c-card_more--btm {
    margin: 2rem 0;
  }
}

.c-card_more--btm {
  margin: 20px 0 28px;
}

@media screen and (max-width: 767px) {
  .c-card_more--btm {
    margin: 2rem 0 2.5rem;
  }
}

.c-card_point {
  width: 100%;
  margin-bottom: 24px;
}

.c-card_ico {
  margin: 0 0 8px;
}

@media screen and (max-width: 767px) {
  .c-card_ico {
    margin: 0 0 1rem;
  }
}

.c-card_box {
  width: 100%;
}

.c-card_btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.c-card_btnItem + .c-card_btnItem {
  margin: 0 0 0 12px;
}

@media screen and (max-width: 767px) {
  .c-card_btnItem + .c-card_btnItem {
    margin: 0 0 0 1.5rem;
  }
}

.c-card_good {
  border-radius: 0 0 20px 20px;
  overflow: hidden;
}

.c-cardDetail {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.c-cardDetail_date {
  position: absolute;
  right: 0;
  bottom: calc(100% + 6px);
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_date {
    bottom: calc(99% + 6px);
  }
}

.c-cardDetail_cont {
  width: 100%;
  padding: 10%;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_cont {
    padding: 3rem 2rem;
  }
}

.c-cardDetail_job {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_job {
    align-items: center;
  }
}

.c-cardDetail_jobBox {
  flex: 1;
}

.c-cardDetail_jobPin {
  width: 50px;
  height: 50px;
  margin: 0 0 0 60px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_jobPin {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 0 0 1rem;
  }
}

.c-cardDetail_intro {
  width: 100%;
  margin: 52px 0 60px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_intro {
    margin: 2rem 0;
  }
}

.c-cardDetail_introCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_introCont {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .c-cardDetail_introBox:first-child {
    flex: 1;
  }
}

.c-cardDetail_introBox:last-child {
  width: 232px;
  margin: 0 0 0 40px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_introBox:last-child {
    width: 100%;
    margin: 1rem 0 0;
  }
}

.c-cardDetail_introInner {
  margin: 12px 0;
  display: flex;
  justify-content: flex-end;
  padding: 0 0 0 52px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_introInner {
    margin: 0.5rem 0;
    padding: 0;
    justify-content: flex-start;
  }
}

.c-cardDetail_introInner:last-child {
  margin-bottom: 0;
}

.c-cardDetail_cat {
  width: 100%;
  margin-top: 12px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_cat {
    margin-top: 1.5rem;
  }
}

.c-cardDetail_wrap {
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .c-cardDetail_wrap {
    max-width: 820px;
  }
}

.c-cardDetail_wrap + .c-cardDetail_wrap {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_wrap + .c-cardDetail_wrap {
    margin-top: 2rem;
  }
}

.c-cardDetail_img {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.c-cardDetail_pic {
  width: 100%;
  height: 450px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_pic {
    height: 20rem;
  }
}

.c-cardDetail_thumbs {
  width: 100%;
  height: 110px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_thumbs {
    height: 5rem;
  }
}

.c-cardDetail_body {
  width: 100%;
  margin: 48px 0 72px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_body {
    margin: 2rem 0 4rem;
  }
}

.c-cardDetail_bodyBox + .c-cardDetail_bodyBox {
  margin: 20px 0 0;
}

.c-cardDetail_comment {
  width: 100%;
  margin: 16px 0 32px;
  padding: 0 60px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_comment {
    margin: 2rem 0;
    padding: 0;
  }
}

.c-cardDetail_ico {
  width: 100%;
  margin: 72px 0 0;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_ico {
    margin: 3rem 0 0;
  }
}

.c-cardDetail_scroll {
  width: 100%;
  height: 180px;
  margin: 24px 0 28px;
  padding: 28px 32px 28px 0;
  position: relative;
}

.c-cardDetail_scrollItem + .c-cardDetail_scrollItem {
  margin: 16px 0 0;
}

.c-cardDetail_close {
  margin: 52px 0 120px;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_close {
    margin: 4rem 0 6rem;
  }
}

.c-cardDetail_release {
  width: 110px;
  display: flex;
  align-items: center;
  font-size: 8px;
  font-weight: bold;
  border-radius: 100px;
  border: 1px solid #3e3a39;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_release {
    width: 40%;
    font-size: 0.9rem;
  }
}

.c-cardDetail_releaseHead {
  width: 50px;
  padding: 4px 0 4px 4px;
  text-align: center;
  color: #fff;
  background-color: #3e3a39;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_releaseHead {
    width: 45%;
    padding: 0.4rem 0 0.4rem 0.4rem;
  }
}

.c-cardDetail_releaseBody {
  flex: 1;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3e3a39;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .c-cardDetail_releaseBody {
    padding: 0.4rem 0;
  }
}

.c-cardDetail_releaseNum {
  display: flex;
  align-items: flex-end;
}

.c-cardDetail_releaseNum + .c-cardDetail_releaseNum:before {
  content: ".";
  font-size: inherit;
  color: inherit;
}

.c-collision, .c-collision--01, .c-collision--02, .c-collision--03 {
  width: 22vw;
  position: absolute;
  animation: 6s linear infinite rotation;
}

@media screen and (min-width: 768px) {
  .c-collision, .c-collision--01, .c-collision--02, .c-collision--03 {
    max-width: 472px;
  }
}

.c-collision--01 {
  will-change: left, top;
}

.c-collision--02 {
  will-change: left, bottom;
}

.c-collision--03 {
  will-change: right, top;
}

.c-collisionCont {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.c-form_input, .c-form_input--inner, .c-form_textarea {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.15em;
  line-height: 1.2307692307692308;
  color: #3e3a39;
  background-color: #f7f8f8;
  border: none;
  -webkit-appearance: none;
}

@media screen and (max-width: 767px) {
  .c-form_input, .c-form_input--inner, .c-form_textarea {
    padding: 1rem;
    font-size: 1.1rem;
  }
}

.c-form_input::-webkit-input-placeholder, .c-form_input--inner::-webkit-input-placeholder, .c-form_textarea::-webkit-input-placeholder {
  color: #9FA0A0;
}

.c-form_input::-moz-placeholder, .c-form_input--inner::-moz-placeholder, .c-form_textarea::-moz-placeholder {
  color: #9FA0A0;
}

.c-form_input:-ms-input-placeholder, .c-form_input--inner:-ms-input-placeholder, .c-form_textarea:-ms-input-placeholder {
  color: #9FA0A0;
}

.c-form_input::-ms-input-placeholder, .c-form_input--inner::-ms-input-placeholder, .c-form_textarea::-ms-input-placeholder {
  color: #9FA0A0;
}

.c-form_input::placeholder, .c-form_input--inner::placeholder, .c-form_textarea::placeholder {
  color: #9FA0A0;
}

.c-form_input:focus, .c-form_input--inner:focus, .c-form_textarea:focus {
  outline: none;
}

.c-form_input--default {
  border-radius: 10px;
}

.c-form_input--rounded {
  border-radius: 100px;
}

.c-form_input--rounded:focus {
  outline: none;
}

.c-form_input--inner {
  padding: 8px;
  font-size: 10px;
}

@media screen and (max-width: 767px) {
  .c-form_input--inner {
    padding: .6rem;
    font-size: .9rem;
  }
}

.c-form_textarea {
  height: 100%;
  resize: none;
}

.c-formFile, .c-formFile--inner {
  width: 250px;
  padding: 15px 0;
  display: block;
  text-align: center;
  color: #fff;
  background-color: #f8b500;
  border: none;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-formFile:after, .c-formFile--inner:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .c-formFile:hover:after, .c-formFile--inner:hover:after {
    opacity: 0.3;
  }
}

@media screen and (max-width: 767px) {
  .c-formFile, .c-formFile--inner {
    width: 100%;
    padding: 1.5rem 0;
  }
}

.c-formFile--inner {
  width: 100%;
  max-width: 150px;
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  .c-formFile--inner {
    max-width: 100%;
  }
}

.c-formFile_input {
  display: none;
}

.c-formFile_item {
  display: none;
}

.c-formFile_name {
  margin: 8px 0;
  display: inline-block;
  font-size: 13px;
}

.c-formRadio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.c-formRadio_input {
  display: none;
}

.c-formRadio_input:checked + span {
  background-color: #d16a36;
}

.c-formRadio_input:checked + span:after {
  opacity: 1;
}

.c-formRadio_ico {
  width: 14px;
  height: 14px;
  margin: 0 6px 0 0;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  border: 1px solid #9FA0A0;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.c-formRadio_ico:after {
  content: '';
  width: 6px !important;
  height: 3px !important;
  display: block;
  background: none;
  border-radius: 0%;
  position: absolute;
  top: calc(50% - 1px) !important;
  left: 50% !important;
  z-index: 2;
  transform: translate(-50%, -50%) rotate(-45deg) !important;
  border-bottom: 2px solid #fff !important;
  border-left: 2px solid #fff !important;
  border-right: none !important;
  opacity: 0;
}

.c-formRadio_text {
  display: inline-block;
}

.c-formCheck {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.c-formCheck_input {
  display: none;
}

.c-formCheck_input:checked + span:after {
  opacity: 1;
}

.c-formCheck_ico {
  width: 14px;
  height: 14px;
  margin: 0 6px 0 0;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  border: 1px solid #9FA0A0;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.c-formCheck_ico:after {
  content: '';
  width: 9px;
  height: 9px;
  display: block;
  background-color: #ec6b2e;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.c-formCheck_text {
  display: inline-block;
}

.c-form_delivery_from {
  width: 190px;
}

.c-heading, .c-heading_large {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 1.1111111111111112;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-heading, .c-heading_large {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .c-heading, .c-heading_large {
    font-size: 1.3rem;
  }
}

.c-heading_large {
  font-size: 20px;
  letter-spacing: 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-heading_large {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .c-heading_large {
    font-size: 1.3rem;
  }
}

.c-heading--tl {
  text-align: left;
}

.c-heading--ta {
  text-align: center;
}

.c-ico {
  width: 100%;
  display: flex;
}

.c-ico_list {
  margin: 0 12px 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .c-ico_list:hover .c-ico_item--good {
    background: url("../img/common/ico_good_orange.svg") no-repeat center;
    background-size: contain;
  }
  .c-ico_list:hover .c-ico_item--comment {
    background: url("../img/common/ico_comment_orange.svg") no-repeat center;
    background-size: contain;
  }
  .c-ico_list:hover .c-ico_num {
    color: #ec6b2e;
  }
}

.c-ico_item, .c-ico_item--good, .c-ico_item--comment {
  width: 25px;
  height: 25px;
  margin: 0 2px 0 0;
  display: block;
}

@media screen and (min-width: 768px) {
  .c-ico_item, .c-ico_item--good, .c-ico_item--comment {
    transition: background 0.3s ease;
  }
}

@media screen and (max-width: 767px) {
  .c-ico_item, .c-ico_item--good, .c-ico_item--comment {
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 0.2rem 0 0;
  }
}

.c-ico_item--good {
  background: url("../img/common/ico_good.svg") no-repeat center;
  background-size: contain;
}

.c-ico_item--comment {
  background: url("../img/common/ico_comment.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-ico_num {
    transition: color 0.3s ease;
  }
}

.c-info, .c-info--multi, .c-info--search {
  width: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .c-info, .c-info--multi, .c-info--search {
    display: block;
  }
}

.c-info--multi {
  align-items: flex-start;
}

.c-info--search {
  position: relative;
  z-index: 2;
}

.c-info_heading {
  width: 120px;
  margin: 0 20px 0 0;
}

@media screen and (max-width: 767px) {
  .c-info_heading {
    width: 100%;
    margin: 0 0 0.5rem 0;
  }
}

.c-info_box {
  flex: 1;
  position: relative;
  z-index: 1;
}

.c-info_boxInner {
  width: 100%;
  position: relative;
  z-index: 3;
}

.c-infoFocus {
  width: 100%;
  position: relative;
  z-index: 1;
}

.c-infoFocus.is-view .c-infoFocus_wrap {
  opacity: 1;
  z-index: 2;
  pointer-events: auto;
}

.c-infoFocus_wrap {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9999;
  opacity: 0;
  pointer-events: none;
}

.c-infoFocus_box {
  padding: 8px 16px;
  background-color: #f7f8f8;
}

@media screen and (max-width: 767px) {
  .c-infoFocus_box {
    padding: 0.6rem 1.5rem;
  }
}

.c-intro_text--work {
  height: 1.2em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.c-introItem {
  width: 100%;
}

.c-introItem_list {
  width: 100%;
  padding: 12px 20px;
  background-color: #fff;
}

.c-introItem_list + .c-introItem_list {
  margin: 4px 0 0;
}

.c-introCont, .c-introCont--name, .c-introCont--comment, .c-introCont--reply, .c-introCont--detail, .c-introCont--center, .c-introCont--message {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .c-introCont, .c-introCont--name, .c-introCont--comment, .c-introCont--reply, .c-introCont--detail, .c-introCont--center, .c-introCont--message {
    height: 100%;
  }
}

.c-introCont--name {
  justify-content: flex-end;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-introCont--name {
    justify-content: space-between;
    padding: 1rem 0;
  }
}

.c-introCont--comment, .c-introCont--reply {
  align-items: flex-start;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-introCont--comment, .c-introCont--reply {
    justify-content: space-between;
  }
}

.c-introCont--comment + .c-introCont--comment, .c-introCont--reply + .c-introCont--comment, .c-introCont--comment + .c-introCont--reply, .c-introCont--reply + .c-introCont--reply {
  margin: 12px 0 0;
  padding: 12px 0 0;
  border-top: 1px dashed #9FA0A0;
}

.c-introCont--reply {
  margin: 0 0 24px;
  padding: 28px 3%;
}

@media screen and (max-width: 767px) {
  .c-introCont--reply {
    margin: 0 0 2rem;
  }
}

.c-introCont--reply .c-introCont_edit {
  overflow: hidden;
}

.c-introCont--reply .c-introCont_edit p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.c-introCont--detail {
  max-width: 100%;
  width: auto;
  padding-bottom: 40px;
  display: inline-flex;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-introCont--detail {
    padding-bottom: 0.5rem;
  }
}

.c-introCont--center {
  align-items: center;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-introCont--center {
    justify-content: space-between;
  }
}

.c-introCont--message {
  align-items: flex-start;
}

.c-introCont_link {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .c-introCont_link:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .c-introCont_link:hover:after {
    opacity: 0.3;
  }
}

.c-introCont_pic, .c-introCont_pic--name, .c-introCont_pic--work, .c-introCont_pic--large, .c-introCont_pic--message, .c-introCont_pic--small, .c-introCont_pic--comment, .c-introCont_pic--add, .c-introCont_pic--detail, .c-introCont_pic--form {
  position: relative;
  z-index: 1;
}

.c-introCont_pic img, .c-introCont_pic--name img, .c-introCont_pic--work img, .c-introCont_pic--large img, .c-introCont_pic--message img, .c-introCont_pic--small img, .c-introCont_pic--comment img, .c-introCont_pic--add img, .c-introCont_pic--detail img, .c-introCont_pic--form img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.c-introCont_pic:after, .c-introCont_pic--name:after, .c-introCont_pic--work:after, .c-introCont_pic--large:after, .c-introCont_pic--message:after, .c-introCont_pic--small:after, .c-introCont_pic--comment:after, .c-introCont_pic--add:after, .c-introCont_pic--detail:after, .c-introCont_pic--form:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #b0dff8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.c-introCont_pic--name {
  width: 30px;
  height: 30px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .c-introCont_pic--name {
    width: 4.5rem;
    height: 4.5rem;
    margin: 0 1rem 0 0;
  }
}

.c-introCont_pic--work, .c-introCont_pic--large {
  width: 52px;
  height: 52px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .c-introCont_pic--work, .c-introCont_pic--large {
    width: 4.5rem;
    height: 4.5rem;
    margin: 0 1rem 0 0;
  }
}

.c-introCont_pic--message {
  width: 40px;
  height: 40px;
  margin: 0 20px 0 0;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .c-introCont_pic--message {
    width: 3rem;
    height: 3rem;
    margin: 0 1.5rem 0 0;
  }
}

.c-introCont_pic--small, .c-introCont_pic--comment, .c-introCont_pic--add, .c-introCont_pic--detail, .c-introCont_pic--form {
  width: 15px;
  height: 15px;
  margin: 0;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .c-introCont_pic--small, .c-introCont_pic--comment, .c-introCont_pic--add, .c-introCont_pic--detail, .c-introCont_pic--form {
    width: 2.2rem;
    height: 2.2rem;
    margin: 0;
  }
}

.c-introCont_pic--large {
  width: 76px;
  height: 76px;
}

.c-introCont_pic--comment {
  margin: 0 4px 0 0;
}

@media screen and (max-width: 767px) {
  .c-introCont_pic--comment {
    margin: 0 0.5rem 0 0;
  }
}

.c-introCont_pic--add {
  width: 20px;
  height: 20px;
  margin: 0 4px 0 0;
}

@media screen and (max-width: 767px) {
  .c-introCont_pic--add {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.5rem 0 0;
  }
}

.c-introCont_pic--detail {
  width: 20px;
  height: 20px;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .c-introCont_pic--detail {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;
  }
}

.c-introCont_pic--form {
  width: 25px;
  height: 25px;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .c-introCont_pic--form {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;
  }
}

.c-introCont_box, .c-introCont_box--comment, .c-introCont_box--message {
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
}

.c-introCont_box--comment {
  justify-content: flex-start;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .c-introCont_box--comment {
    padding: 0.4rem 0 0;
  }
}

.c-introCont_box--message {
  padding: 12px 0 0;
}

@media screen and (max-width: 767px) {
  .c-introCont_box--message {
    padding: 0.6rem 0 0;
  }
}

.c-introCont_box--message .c-introCont_textarea {
  padding: 8px;
  font-size: 14px;
  line-height: 1.5384615384615385;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-introCont_box--message .c-introCont_textarea {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .c-introCont_box--message .c-introCont_textarea {
    font-size: 1.2rem;
  }
}

.c-introCont_box--message .c-introCont_btnItem, .c-introCont_box--message .c-introCont_btnItem--cancel, .c-introCont_box--message .c-introCont_btnItem--complete {
  font-size: 13px;
}

@media screen and (max-width: 767px) {
  .c-introCont_box--message .c-introCont_btnItem, .c-introCont_box--message .c-introCont_btnItem--cancel, .c-introCont_box--message .c-introCont_btnItem--complete {
    font-size: 1.2rem;
  }
}

.c-introCont_box--message + .c-introConfig {
  width: 60px;
}

.c-introCont_box--message + .c-introConfig .c-introConfig_list {
  width: 24px;
  height: 24px;
}

.c-introCont_box--message + .c-introConfig .c-introConfig_ico--edit {
  width: 13px;
  height: 13px;
}

.c-introCont_box--message + .c-introConfig .c-introConfig_ico--trash {
  width: 11px;
  height: 13px;
}

.c-introCont_text--name {
  line-height: 1.75;
}

.c-introCont_edit {
  width: 100%;
}

.c-introCont_textarea {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  font-size: 11px;
  line-height: 1.4545454545454546;
  letter-spacing: 0.05em;
  -webkit-appearance: none;
  resize: none;
  border: 1px solid #3e3a39;
  border-radius: 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-introCont_textarea {
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .c-introCont_textarea {
    font-size: 1rem;
  }
}

.c-introCont_btn {
  width: 100%;
  margin: 8px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.c-introCont_btn.is-hide {
  display: none;
}

.c-introCont_btnItem, .c-introCont_btnItem--cancel, .c-introCont_btnItem--complete {
  padding: 4px 12px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  background-color: #fff;
  border-radius: 100px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-introCont_btnItem, .c-introCont_btnItem--cancel, .c-introCont_btnItem--complete {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .c-introCont_btnItem:hover, .c-introCont_btnItem--cancel:hover, .c-introCont_btnItem--complete:hover {
    color: #fff;
    background-color: #ec6b2e;
  }
}

.c-introCont_btnItem + .c-introCont_btnItem, .c-introCont_btnItem--cancel + .c-introCont_btnItem, .c-introCont_btnItem--complete + .c-introCont_btnItem, .c-introCont_btnItem + .c-introCont_btnItem--cancel, .c-introCont_btnItem--cancel + .c-introCont_btnItem--cancel, .c-introCont_btnItem--complete + .c-introCont_btnItem--cancel, .c-introCont_btnItem + .c-introCont_btnItem--complete, .c-introCont_btnItem--cancel + .c-introCont_btnItem--complete, .c-introCont_btnItem--complete + .c-introCont_btnItem--complete {
  margin: 0 0 0 8px;
}

@media screen and (max-width: 767px) {
  .c-introCont_btnItem + .c-introCont_btnItem, .c-introCont_btnItem--cancel + .c-introCont_btnItem, .c-introCont_btnItem--complete + .c-introCont_btnItem, .c-introCont_btnItem + .c-introCont_btnItem--cancel, .c-introCont_btnItem--cancel + .c-introCont_btnItem--cancel, .c-introCont_btnItem--complete + .c-introCont_btnItem--cancel, .c-introCont_btnItem + .c-introCont_btnItem--complete, .c-introCont_btnItem--cancel + .c-introCont_btnItem--complete, .c-introCont_btnItem--complete + .c-introCont_btnItem--complete {
    margin: 0 0 0 1rem;
  }
}

.c-introCont_btnItem--cancel {
  color: #3e3a39;
}

.c-introCont_btnItem--complete {
  color: #ec6b2e;
}

.c-introConfig {
  width: 40px;
  margin: 0 0 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .c-introConfig {
    width: 20%;
    margin: 0 0 0 0.5rem;
    padding: 0.3rem 0 0;
  }
}

.c-introConfig_list {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-introConfig_list {
    transition: background-color 0.3s ease;
  }
  .c-introConfig_list:hover {
    background-color: #ec6b2e;
  }
  .c-introConfig_list:hover .c-introConfig_ico--edit {
    background: url("../img/common/ico_comment_edit_white.svg") no-repeat center;
    background-size: contain;
  }
  .c-introConfig_list:hover .c-introConfig_ico--trash {
    background: url("../img/common/ico_comment_trash_white.svg") no-repeat center;
    background-size: contain;
  }
}

@media screen and (max-width: 767px) {
  .c-introConfig_list {
    width: 2rem;
    height: 2rem;
    background-color: #ec6b2e;
  }
  .c-introConfig_list .c-introConfig_ico--edit {
    background: url("../img/common/ico_comment_edit_white.svg") no-repeat center;
    background-size: contain;
  }
  .c-introConfig_list .c-introConfig_ico--trash {
    background: url("../img/common/ico_comment_trash_white.svg") no-repeat center;
    background-size: contain;
  }
}

.c-introConfig_list:first-child.is-active {
  pointer-events: none;
}

.c-introConfig_ico--edit {
  width: 10px;
  height: 10px;
  background: url("../img/common/ico_comment_edit.svg") no-repeat center;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .c-introConfig_ico--edit {
    width: 0.9rem;
    height: 0.9rem;
  }
}

.c-introConfig_ico--trash {
  width: 8px;
  height: 10px;
  background: url("../img/common/ico_comment_trash.svg") no-repeat center;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .c-introConfig_ico--trash {
    width: 0.7rem;
    height: 0.9rem;
  }
}

.c-job, .c-job--modal, .c-job--detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-job--modal {
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .c-job--modal {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.c-job--modal .c-job_list, .c-job--modal .c-job_listModal, .c-job--modal .c-job_listModal--graphic, .c-job--modal .c-job_listModal--web, .c-job--modal .c-job_listModal--edit, .c-job--modal .c-job_listModal--movie, .c-job--modal .c-job_listModal--event {
  cursor: pointer;
  pointer-events: auto;
  color: #9FA0A0;
  background-color: #efefef;
}

@media screen and (min-width: 768px) {
  .c-job--modal .c-job_list, .c-job--modal .c-job_listModal, .c-job--modal .c-job_listModal--graphic, .c-job--modal .c-job_listModal--web, .c-job--modal .c-job_listModal--edit, .c-job--modal .c-job_listModal--movie, .c-job--modal .c-job_listModal--event {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .c-job--modal .c-job_list:hover, .c-job--modal .c-job_listModal:hover, .c-job--modal .c-job_listModal--graphic:hover, .c-job--modal .c-job_listModal--web:hover, .c-job--modal .c-job_listModal--edit:hover, .c-job--modal .c-job_listModal--movie:hover, .c-job--modal .c-job_listModal--event:hover {
    color: #fff;
    background-color: #ec6b2e;
  }
}

@media screen and (max-width: 767px) {
  .c-job--modal .c-job_list, .c-job--modal .c-job_listModal, .c-job--modal .c-job_listModal--graphic, .c-job--modal .c-job_listModal--web, .c-job--modal .c-job_listModal--edit, .c-job--modal .c-job_listModal--movie, .c-job--modal .c-job_listModal--event {
    width: 46%;
    margin: 0;
  }
  .c-job--modal .c-job_list:nth-child(n + 3), .c-job--modal .c-job_listModal:nth-child(n + 3), .c-job--modal .c-job_listModal--graphic:nth-child(n + 3), .c-job--modal .c-job_listModal--web:nth-child(n + 3), .c-job--modal .c-job_listModal--edit:nth-child(n + 3), .c-job--modal .c-job_listModal--movie:nth-child(n + 3), .c-job--modal .c-job_listModal--event:nth-child(n + 3) {
    margin: 1rem 0 0;
  }
}

@media screen and (max-width: 767px) {
  .c-job--detail {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.c-job--detail .c-job_list, .c-job--detail .c-job_listModal, .c-job--detail .c-job_listModal--graphic, .c-job--detail .c-job_listModal--web, .c-job--detail .c-job_listModal--edit, .c-job--detail .c-job_listModal--movie, .c-job--detail .c-job_listModal--event {
  width: 24%;
  border-radius: 10px;
}

@media screen and (min-width: 768px) {
  .c-job--detail .c-job_list, .c-job--detail .c-job_listModal, .c-job--detail .c-job_listModal--graphic, .c-job--detail .c-job_listModal--web, .c-job--detail .c-job_listModal--edit, .c-job--detail .c-job_listModal--movie, .c-job--detail .c-job_listModal--event {
    max-width: 130px;
    height: 50px;
  }
}

@media screen and (max-width: 767px) {
  .c-job--detail .c-job_list, .c-job--detail .c-job_listModal, .c-job--detail .c-job_listModal--graphic, .c-job--detail .c-job_listModal--web, .c-job--detail .c-job_listModal--edit, .c-job--detail .c-job_listModal--movie, .c-job--detail .c-job_listModal--event {
    width: 48%;
    height: 2.4rem;
  }
  .c-job--detail .c-job_list:nth-child(n + 3), .c-job--detail .c-job_listModal:nth-child(n + 3), .c-job--detail .c-job_listModal--graphic:nth-child(n + 3), .c-job--detail .c-job_listModal--web:nth-child(n + 3), .c-job--detail .c-job_listModal--edit:nth-child(n + 3), .c-job--detail .c-job_listModal--movie:nth-child(n + 3), .c-job--detail .c-job_listModal--event:nth-child(n + 3) {
    margin: 1rem 0 0;
  }
}

@media screen and (min-width: 768px) {
  .c-job--detail .c-job_list:after, .c-job--detail .c-job_listModal:after, .c-job--detail .c-job_listModal--graphic:after, .c-job--detail .c-job_listModal--web:after, .c-job--detail .c-job_listModal--edit:after, .c-job--detail .c-job_listModal--movie:after, .c-job--detail .c-job_listModal--event:after {
    width: 80%;
    height: 16px;
  }
}

@media screen and (min-width: 768px) {
  .c-job--detail .c-job_list--graphic:after {
    max-width: 113px;
  }
}

@media screen and (min-width: 768px) {
  .c-job--detail .c-job_list--web:after {
    max-width: 57px;
  }
}

@media screen and (min-width: 768px) {
  .c-job--detail .c-job_list--edit:after {
    max-width: 55px;
  }
}

@media screen and (min-width: 768px) {
  .c-job--detail .c-job_list--movie:after {
    max-width: 78px;
  }
}

@media screen and (min-width: 768px) {
  .c-job--detail .c-job_list--event:after {
    max-width: 78px;
  }
}

.c-job_list, .c-job_listModal, .c-job_listModal--graphic, .c-job_listModal--web, .c-job_listModal--edit, .c-job_listModal--movie, .c-job_listModal--event {
  width: 22%;
  height: 20px;
  background-color: #fdeadd;
  border-radius: 4px;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .c-job_list, .c-job_listModal, .c-job_listModal--graphic, .c-job_listModal--web, .c-job_listModal--edit, .c-job_listModal--movie, .c-job_listModal--event {
    max-width: 50px;
  }
}

@media screen and (max-width: 767px) {
  .c-job_list, .c-job_listModal, .c-job_listModal--graphic, .c-job_listModal--web, .c-job_listModal--edit, .c-job_listModal--movie, .c-job_listModal--event {
    width: 18%;
  }
}

.c-job_list.is-active, .is-active.c-job_listModal, .is-active.c-job_listModal--graphic, .is-active.c-job_listModal--web, .is-active.c-job_listModal--edit, .is-active.c-job_listModal--movie, .is-active.c-job_listModal--event {
  background-color: #ec6b2e;
}

.c-job_list:after, .c-job_listModal:after, .c-job_listModal--graphic:after, .c-job_listModal--web:after, .c-job_listModal--edit:after, .c-job_listModal--movie:after, .c-job_listModal--event:after {
  content: '';
  width: 100%;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .c-job_list:after, .c-job_listModal:after, .c-job_listModal--graphic:after, .c-job_listModal--web:after, .c-job_listModal--edit:after, .c-job_listModal--movie:after, .c-job_listModal--event:after {
    height: 0.6rem;
  }
}

.c-job_list--graphic:after {
  background: url("../img/common/job_graphic.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_list--graphic:after {
    max-width: 46px;
  }
}

.c-job_list--web:after {
  background: url("../img/common/job_web.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_list--web:after {
    max-width: 23px;
  }
}

.c-job_list--edit:after {
  background: url("../img/common/job_edit.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_list--edit:after {
    max-width: 22px;
  }
}

.c-job_list--movie:after {
  background: url("../img/common/job_movie.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_list--movie:after {
    max-width: 32px;
  }
}

.c-job_list--event:after {
  background: url("../img/common/job_event.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_list--event:after {
    max-width: 32px;
  }
}

.c-job_listModal, .c-job_listModal--graphic, .c-job_listModal--web, .c-job_listModal--edit, .c-job_listModal--movie, .c-job_listModal--event {
  margin: 0 14px 0 0;
}

.c-job_listModal--graphic:after {
  background: url("../img/common/job_graphic.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_listModal--graphic:after {
    max-width: 46px;
  }
}

.c-job_listModal--web:after {
  background: url("../img/common/job_web.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_listModal--web:after {
    max-width: 23px;
  }
}

.c-job_listModal--edit:after {
  background: url("../img/common/job_edit.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_listModal--edit:after {
    max-width: 22px;
  }
}

.c-job_listModal--movie:after {
  background: url("../img/common/job_movie.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_listModal--movie:after {
    max-width: 32px;
  }
}

.c-job_listModal--event:after {
  background: url("../img/common/job_event.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .c-job_listModal--event:after {
    max-width: 32px;
  }
}

.c-listCat {
  width: 100%;
}

.c-listCat_cont, .c-listCat_cont--inner {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .c-listCat_cont, .c-listCat_cont--inner {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .c-listCat_cont + .c-listCat_cont, .c-listCat_cont--inner + .c-listCat_cont, .c-listCat_cont + .c-listCat_cont--inner, .c-listCat_cont--inner + .c-listCat_cont--inner {
    margin-top: 1rem;
  }
}

.c-listCat_cont--inner {
  margin-bottom: 12px;
  display: block;
}

.c-listCat_cont--inner .c-listCat_contHead {
  width: 100%;
  margin-right: 0;
  padding: 0;
}

.c-listCat_cont--inner .c-listCat_contBody {
  width: 100%;
}

.c-listCat_contHead {
  width: 135px;
  margin-right: 12px;
  padding: 5px 0;
}

@media screen and (max-width: 767px) {
  .c-listCat_contHead {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.2rem;
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  .c-listCat_contBody {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .c-listCat_contBody {
    width: 100%;
  }
}

.c-listCat_contUnit {
  display: flex;
  flex-wrap: wrap;
}

.c-listCat_contUnitItem {
  cursor: default !important;
}

.c-modalOverlay, .c-modalOverlay--transparent {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.c-modalOverlay:after, .c-modalOverlay--transparent:after {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #f8b500 0%, #ffe73f 100%);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.c-modalOverlay.is-view, .is-view.c-modalOverlay--transparent {
  pointer-events: auto;
}

.c-modalOverlay.is-view:after, .is-view.c-modalOverlay--transparent:after {
  opacity: 1;
}

.c-modalOverlay--transparent {
  inset: 0px;
}

.c-modalOverlay--transparent.is-view:after {
  opacity: 0.75;
}

.c-modalOverlay_wrap {
  position: absolute;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.c-modalClose {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .c-modalClose {
    width: 2rem;
    height: 2rem;
    top: 1.5rem;
    right: 1.5rem;
  }
}

.c-modalClose:before, .c-modalClose:after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #9FA0A0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
}

.c-modalClose:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.c-modalClose:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.c-modalSmall {
  width: 100%;
  padding: 80px 0;
  background-color: #fff;
  border-radius: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.c-modalSmall.is-view {
  opacity: 1;
  pointer-events: auto;
}

@media screen and (min-width: 768px) {
  .c-modalSmall {
    max-width: 580px;
  }
}

@media screen and (max-width: 767px) {
  .c-modalSmall {
    width: 90%;
    padding: 5rem 0;
  }
}

.c-modalSmall_intro {
  margin: 0 0 32px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-modalSmall_intro {
    margin: 0 0 2rem;
  }
}

.c-modalSmall_lead {
  padding: 0 0 16px;
}

@media screen and (max-width: 767px) {
  .c-modalSmall_lead {
    padding: 0 0 1rem;
  }
}

.c-modalSmall_box {
  width: 360px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .c-modalSmall_box {
    width: 85%;
  }
}

.c-modalSmall_boxItem {
  width: 100%;
}

.c-modalSmall_boxItem + .c-modalSmall_boxItem {
  margin: 20px 0 0;
}

@media screen and (max-width: 767px) {
  .c-modalSmall_boxItem + .c-modalSmall_boxItem {
    margin: 1rem 0 0;
  }
}

.c-modalSmall_btn {
  margin: 40px 0 0;
}

@media screen and (max-width: 767px) {
  .c-modalSmall_btn {
    margin: 2.5rem 0 0;
  }
}

.c-modalMain, .c-modalMain--smart {
  width: 100%;
  padding: 48px 0 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.c-modalMain.is-view, .is-view.c-modalMain--smart {
  opacity: 1;
  pointer-events: auto;
}

@media screen and (min-width: 768px) {
  .c-modalMain, .c-modalMain--smart {
    max-width: 800px;
  }
}

@media screen and (max-width: 767px) {
  .c-modalMain, .c-modalMain--smart {
    width: 95%;
    max-height: 90vh;
    padding: 3rem 0;
  }
}

.c-modalMain--smart {
  margin: 12% auto 0;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
  .c-modalMain--smart {
    max-width: 620px;
  }
}

@media screen and (max-width: 767px) {
  .c-modalMain--smart {
    max-height: none;
    margin: 8rem auto 0;
  }
}

.c-modalMain_heading {
  margin: 0 0 20px;
}

@media screen and (max-width: 767px) {
  .c-modalMain_heading {
    margin: 0 0 1.5rem;
  }
}

.c-modalMain_cont, .c-modalMain_cont--smart, .c-modalMain_cont--possible {
  width: 100%;
  height: 300px;
  margin: 0 auto 24px;
  padding: 24px 10px;
  background: #fff;
  border-radius: 20px;
}

@media screen and (min-width: 768px) {
  .c-modalMain_cont, .c-modalMain_cont--smart, .c-modalMain_cont--possible {
    max-width: 600px;
  }
}

@media screen and (max-width: 767px) {
  .c-modalMain_cont, .c-modalMain_cont--smart, .c-modalMain_cont--possible {
    width: 90%;
    height: 50vh;
    margin: 0 auto 2rem;
    padding: 2rem 1rem;
  }
}

.c-modalMain_cont--smart {
  height: auto;
  background: none;
  padding: 0;
  border-radius: 0;
}

@media screen and (min-width: 768px) {
  .c-modalMain_cont--smart {
    max-width: 480px;
  }
}

@media screen and (max-width: 767px) {
  .c-modalMain_cont--smart {
    height: auto;
    padding: 0;
  }
}

.c-modalMain_cont--possible {
  max-height: 300px;
  height: auto;
}

.c-modalMain_scroll {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  position: relative;
}

.c-modalMain_box {
  margin: 8px 0 0;
  padding: 8px 0 0;
  border-top: 1px solid #f7f8f8;
}

@media screen and (max-width: 767px) {
  .c-modalMain_box {
    margin: 1rem 0 0;
    padding: 1rem 0 0;
  }
}

.c-modalPic {
  width: 90%;
  height: 80%;
  padding: 3%;
  border-radius: 30px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.c-modalPic.is-view {
  opacity: 1;
  pointer-events: auto;
}

.c-modalPic_cont {
  width: 100%;
  height: 100%;
}

.c-modalPic_box {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.c-modalPic_box img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

.c-not {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

.c-not_cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-not_circle, .c-not_circle--sp {
  width: 300px;
  height: 300px;
  margin: 0 30px;
  border: 12px solid red;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.c-not_circle:before, .c-not_circle--sp:before {
  content: '';
  width: 100%;
  height: 10px;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%) rotate(45deg);
}

.c-not_circle:after, .c-not_circle--sp:after {
  content: '';
  width: 65%;
  height: 65%;
  background: url("../img/common/logo_ie.svg") no-repeat center;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -2;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .c-not_circle--sp {
    width: 40vw;
    height: 40vw;
    border-width: 8px;
  }
}

.c-not_circle--sp:after {
  width: 70%;
  height: 70%;
  background: url("../img/common/img_smartphone.png") no-repeat center;
  background-size: contain;
}

.c-not_dame {
  width: 300px;
}

@media screen and (max-width: 767px) {
  .c-not_dame {
    width: 25vw;
  }
}

.c-not_dame img {
  width: 100%;
}

.c-not_link {
  width: auto;
  margin: 32px 0 0;
  display: inline-block;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .c-not_link {
    transition: opacity 0.3s ease;
  }
  .c-not_link:hover {
    opacity: 0.7;
  }
}

.c-person {
  width: 100%;
  padding: 0 8px 0 0;
  display: flex;
  flex-wrap: wrap;
}

.c-person--detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .c-person--detail {
    justify-content: flex-start;
  }
}

.c-person_list {
  width: 15px;
  margin: 0 4px 0 0;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-person_list {
    width: 2.2rem;
    height: 100%;
    margin: 0 1rem 0 0;
  }
}

.c-person_list--detail {
  width: 20px;
  margin: 2px 0 2px 8px;
}

@media screen and (max-width: 767px) {
  .c-person_list--detail {
    width: 2.5rem;
    margin: .5rem 1rem .5rem 0;
  }
}

.is-hide .c-person {
  height: 100%;
  position: relative;
  z-index: 1;
}

.is-hide .c-person:before {
  content: '･･･';
  display: block;
  font-size: 8px;
  color: #9FA0A0;
  letter-spacing: -0.2em;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 3;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .is-hide .c-person:before {
    font-size: 1rem;
    letter-spacing: 0;
    right: 0.5rem;
  }
}

.is-hide .c-person:after {
  content: '';
  width: 12px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .is-hide .c-person:after {
    width: 1.6rem;
    right: 0.5rem;
  }
}

.c-pop, .c-pop--profile {
  min-width: 120px;
  padding: 12px;
  background-color: #ec6b2e;
  border-radius: 10px 10px 10px 8px;
  position: absolute;
  bottom: calc(100% + 20px);
  left: 0;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

@media screen and (max-width: 767px) {
  .c-pop, .c-pop--profile {
    display: none;
  }
}

.c-pop:after, .c-pop--profile:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 0 0;
  border-color: #ec6b2e transparent transparent transparent;
  position: absolute;
  top: calc(100% - 8px);
  left: 0;
  z-index: 2;
}

.c-pop--profile {
  padding: 2px 12px;
  bottom: 70%;
}

@media screen and (min-width: 768px) {
  .c-pop.is-view, .is-view.c-pop--profile {
    opacity: 1;
  }
}

.c-pop_list + .c-pop_list {
  margin: 6px 0 0;
}

.c-profile {
  width: 100%;
  margin: 0 0 80px;
  /* PICK UP実績 */
  /* end PICK UP実績 */
  /* 実績&データ */
  /* end 実績&データ */
}

@media screen and (max-width: 767px) {
  .c-profile {
    margin: 0 0 4rem;
  }
}

.c-profile_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .c-profile_cont {
    display: block;
  }
}

.c-profile_box:first-child {
  width: 350px;
  padding: 20px 44px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #ec6b2e;
  border-radius: 0 20px 20px 0;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .c-profile_box:first-child {
    width: 100%;
    padding: 3rem 2rem;
    border-radius: 0 0 20px 20px;
  }
}

.c-profile_box:last-child {
  padding: 28px 44px;
}

@media screen and (min-width: 768px) {
  .c-profile_box:last-child {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .c-profile_box:last-child {
    padding: 3rem 2rem;
  }
}

.c-profile_link {
  width: 100%;
  margin: 28px 0 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-profile_link {
    margin: 2rem 0 0;
  }
}

.c-profile_lead {
  margin: 0 0 12px;
}

.c-profileInfo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-profileInfo_pic {
  width: 80px;
  height: 80px;
  margin: 0 16px 0 0;
  border-radius: 50%;
  overflow: hidden;
}

.c-profileInfo_pic img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.c-profileInfo_box {
  flex: 1;
}

.c-profileInfo_inner {
  text-align: left;
}

.c-profileInfo_inner + .c-profileInfo_inner {
  margin: 8px 0 0;
}

.c-profileIntro {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-profileIntro {
    height: 2.5rem;
  }
}

.c-profileIntro:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url("../img/common/bg_profile.png") no-repeat center bottom;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .c-profileIntro:after {
    background-size: cover;
  }
}

.c-profileIntro_heading {
  width: 100%;
  position: relative;
  z-index: 2;
}

.c-profileTab {
  width: 100%;
  position: relative;
  z-index: 1;
}

.c-profileTab_cont {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}

.c-profileTab_cont.is-left {
  background: url("../img/common/bg_tab_left.png") no-repeat left 6px;
  background-size: 100% 70px;
}

.c-profileTab_cont.is-left .c-profileTab_contList:first-child .c-profileTab_contItem {
  color: #fff;
  pointer-events: none;
}

.c-profileTab_cont.is-right {
  background: url("../img/common/bg_tab_right.png") no-repeat right 6px;
  background-size: 100% 70px;
}

.c-profileTab_cont.is-right .c-profileTab_contList:last-child .c-profileTab_contItem {
  color: #fff;
  pointer-events: none;
}

.c-profileTab_contList {
  width: 50%;
  height: 100%;
}

.c-profileTab_contList:first-child {
  padding-right: 240px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-profileTab_contList:first-child {
    padding-right: 160px;
  }
}

@media screen and (max-width: 767px) {
  .c-profileTab_contList:first-child {
    padding-right: 0;
  }
}

.c-profileTab_contList:first-child .c-profileTab_contItem {
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .c-profileTab_contList:first-child .c-profileTab_contItem {
    justify-content: center;
  }
}

.c-profileTab_contList:last-child {
  padding-left: 240px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-profileTab_contList:last-child {
    padding-left: 160px;
  }
}

@media screen and (max-width: 767px) {
  .c-profileTab_contList:last-child {
    padding-left: 0;
  }
}

.c-profileTab_contList:last-child .c-profileTab_contItem {
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .c-profileTab_contList:last-child .c-profileTab_contItem {
    justify-content: center;
  }
}

.c-profileTab_contItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #3e3a39;
}

@media screen and (min-width: 768px) {
  .c-profileTab_contItem {
    transition: opacity 0.3s ease;
  }
  .c-profileTab_contItem:hover {
    opacity: 0.7;
  }
}

.c-profileCont {
  padding: 60px 0 72px;
}

@media screen and (max-width: 767px) {
  .c-profileCont {
    padding: 4rem 0;
  }
}

.c-profileCont--color, .c-profileCont--color02 {
  padding: 48px 0 72px;
  background-color: #f8b500;
}

@media screen and (max-width: 767px) {
  .c-profileCont--color, .c-profileCont--color02 {
    padding: 4rem 0;
  }
}

.c-profileCont--color02 {
  padding: 72px 0;
  background-color: #e7b421;
}

@media screen and (max-width: 767px) {
  .c-profileCont--color02 {
    padding: 4rem 0;
  }
}

.c-scroll {
  overflow-y: scroll;
}

.c-scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #f7f8f8;
  border-radius: 50px;
}

.c-scroll::-webkit-scrollbar-thumb {
  width: 8px;
  height: 90px;
  background-color: #f8b500;
  border-radius: 50px;
}

.c-slider .swiper-container {
  width: 100%;
  padding: 0 60px;
}

@media screen and (max-width: 767px) {
  .c-slider .swiper-container {
    padding: 0;
  }
}

.c-slider .swiper-button-next, .c-slider .swiper-button-prev {
  width: 30px;
  height: 30px;
  background-color: #f7f8f8;
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .c-slider .swiper-button-next, .c-slider .swiper-button-prev {
    transition: background-color 0.3s ease;
  }
  .c-slider .swiper-button-next:before, .c-slider .swiper-button-prev:before {
    transition: border-color 0.3s ease;
  }
  .c-slider .swiper-button-next:hover, .c-slider .swiper-button-prev:hover {
    background-color: #f8b500;
  }
}

@media screen and (max-width: 767px) {
  .c-slider .swiper-button-next, .c-slider .swiper-button-prev {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.c-slider .swiper-button-next:before, .c-slider .swiper-button-prev:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.c-slider .swiper-button-next:after, .c-slider .swiper-button-prev:after {
  display: none;
}

@media screen and (max-width: 767px) {
  .c-slider .swiper-button-next {
    right: 0;
  }
}

.c-slider .swiper-button-next:before {
  left: calc(50% + 2px);
  border-width: 7.5px 0 7.5px 12px;
  border-color: transparent transparent transparent #f8b500;
}

@media screen and (min-width: 768px) {
  .c-slider .swiper-button-next:hover:before {
    border-color: transparent transparent transparent #fff;
  }
}

@media screen and (max-width: 767px) {
  .c-slider .swiper-button-prev {
    left: 0;
  }
}

.c-slider .swiper-button-prev:before {
  left: calc(50% - 2px);
  border-width: 7.5px 12px 7.5px 0;
  border-color: transparent #f8b500 transparent transparent;
}

@media screen and (min-width: 768px) {
  .c-slider .swiper-button-prev:hover:before {
    border-color: transparent #fff transparent transparent;
  }
}

.c-sliderThumbs .swiper-wrapper {
  justify-content: space-between;
  flex-wrap: wrap;
  transform: none !important;
  transition: none !important;
  border-radius: 0 !important;
}

@media screen and (min-width: 768px) {
  .c-sliderThumbs .swiper-wrapper:before, .c-sliderThumbs .swiper-wrapper:after {
    content: '';
    width: 23%;
  }
  .c-sliderThumbs .swiper-wrapper:before {
    order: 1;
  }
  .c-sliderThumbs .swiper-wrapper:after {
    order: 2;
  }
}

@media screen and (max-width: 767px) {
  .c-sliderThumbs .swiper-wrapper:after {
    content: '';
    width: 32%;
    display: block;
  }
}

.c-sliderThumbs .swiper-slide {
  width: 23% !important;
  margin: 0 !important;
  border: 4px solid transparent !important;
  border-radius: 16px;
  overflow: hidden;
  transition: border-color 0.3s ease !important;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-sliderThumbs .swiper-slide {
    max-width: 160px !important;
  }
  .c-sliderThumbs .swiper-slide:nth-child(n + 5) {
    margin: 20px 0 0 !important;
  }
  .c-sliderThumbs .swiper-slide:hover {
    border-color: #ec6b2e !important;
  }
}

@media screen and (max-width: 767px) {
  .c-sliderThumbs .swiper-slide {
    width: 32% !important;
    border-radius: 8px;
    border-width: 2px !important;
  }
  .c-sliderThumbs .swiper-slide:nth-child(n + 4) {
    margin: 0.5rem 0 0 !important;
  }
}

.c-sliderThumbs .swiper-slide-thumb-active {
  border-color: #ec6b2e !important;
}

.c-summaryCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .c-summaryCont {
    display: block;
  }
}

.c-summaryCont + .c-summaryCont {
  margin-top: 16px;
}

@media screen and (max-width: 767px) {
  .c-summaryCont + .c-summaryCont {
    margin-top: 2rem;
  }
}

.c-summaryCont_head {
  width: 132px;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 768px) {
  .c-summaryCont_head {
    margin-right: 12px;
  }
}

@media screen and (max-width: 767px) {
  .c-summaryCont_head {
    width: 100%;
    height: auto;
    margin-bottom: 12px;
    font-size: .9rem;
  }
}

.c-summaryCont_body {
  padding: 14px 16px;
  border: 1px solid #9FA0A0;
  border-radius: 10px;
}

@media screen and (min-width: 768px) {
  .c-summaryCont_body {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .c-summaryCont_body {
    width: 100%;
    padding: .5rem 1rem;
  }
}

.c-summaryCont_text, .c-summaryCont_link {
  font-size: 13px;
  line-height: 1.8461538461538463;
  color: #595757;
}

@media screen and (max-width: 767px) {
  .c-summaryCont_text, .c-summaryCont_link {
    font-size: .8rem;
  }
}

@media screen and (min-width: 768px) {
  .c-summaryCont_link {
    transition: opacity .3s ease, color .3s ease;
  }
  .c-summaryCont_link:hover {
    opacity: .7;
    color: #f8b500;
  }
}

.c-tag, .c-tag--top {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .c-tag--view {
    height: auto !important;
  }
}

.c-tag--view .c-tag_list, .c-tag--view .c-tag_list--yellow, .c-tag--view .c-tag_list--green, .c-tag--view .c-tag_list--pink, .c-tag--view .c-tag_list--orange, .c-tag--view .c-tag_list--orange02, .c-tag--view .c-tag_list--blue, .c-tag--view .c-tag_listModal, .c-tag--view .c-tag_listModal--yellow, .c-tag--view .c-tag_listModal--green, .c-tag--view .c-tag_listModal--pink, .c-tag--view .c-tag_listModal--orange, .c-tag--view .c-tag_listModal--orange02, .c-tag--view .c-tag_listModal--blue {
  cursor: default;
}

.c-tag--detail {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .c-tag--detail {
    display: block;
  }
}

.c-tag_list, .c-tag_list--yellow, .c-tag_list--green, .c-tag_list--pink, .c-tag_list--orange, .c-tag_list--orange02, .c-tag_list--blue, .c-tag_listModal, .c-tag_listModal--yellow, .c-tag_listModal--green, .c-tag_listModal--pink, .c-tag_listModal--orange, .c-tag_listModal--orange02, .c-tag_listModal--blue {
  margin: 2px 4px 2px 0;
  padding: 4px 10px;
  display: inline-block;
  line-height: 1 !important;
  color: #9FA0A0;
  background-color: #efefef;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-tag_list, .c-tag_list--yellow, .c-tag_list--green, .c-tag_list--pink, .c-tag_list--orange, .c-tag_list--orange02, .c-tag_list--blue, .c-tag_listModal, .c-tag_listModal--yellow, .c-tag_listModal--green, .c-tag_listModal--pink, .c-tag_listModal--orange, .c-tag_listModal--orange02, .c-tag_listModal--blue {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .c-tag_list:hover, .c-tag_list--yellow:hover, .c-tag_list--green:hover, .c-tag_list--pink:hover, .c-tag_list--orange:hover, .c-tag_list--orange02:hover, .c-tag_list--blue:hover, .c-tag_listModal:hover, .c-tag_listModal--yellow:hover, .c-tag_listModal--green:hover, .c-tag_listModal--pink:hover, .c-tag_listModal--orange:hover, .c-tag_listModal--orange02:hover, .c-tag_listModal--blue:hover {
    color: #fff;
  }
}

.c-tag_list--yellow.is-active {
  color: #fff;
  background-color: #f8b500;
}

@media screen and (min-width: 768px) {
  .c-tag_list--yellow:hover {
    background-color: #f8b500;
  }
}

.c-tag_list--green.is-active {
  color: #fff;
  background-color: #009fa8;
}

@media screen and (min-width: 768px) {
  .c-tag_list--green:hover {
    background-color: #009fa8;
  }
}

.c-tag_list--pink.is-active {
  color: #fff;
  background-color: #e85298;
}

@media screen and (min-width: 768px) {
  .c-tag_list--pink:hover {
    background-color: #e85298;
  }
}

.c-tag_list--orange.is-active {
  color: #fff;
  background-color: #ec6b2e;
}

@media screen and (min-width: 768px) {
  .c-tag_list--orange:hover {
    background-color: #ec6b2e;
  }
}

.c-tag_list--orange02.is-active {
  color: #fff;
  background-color: #EA5514;
}

@media screen and (min-width: 768px) {
  .c-tag_list--orange02:hover {
    background-color: #EA5514;
  }
}

.c-tag_list--blue.is-active {
  color: #fff;
  background-color: #4c8dcb;
}

@media screen and (min-width: 768px) {
  .c-tag_list--blue:hover {
    background-color: #4c8dcb;
  }
}

.c-tag_listModal, .c-tag_listModal--yellow, .c-tag_listModal--green, .c-tag_listModal--pink, .c-tag_listModal--orange, .c-tag_listModal--orange02, .c-tag_listModal--blue {
  margin: 5px 14px 5px 0;
}

.c-tag_listModal--yellow.is-active {
  color: #fff;
  background-color: #f8b500;
}

@media screen and (min-width: 768px) {
  .c-tag_listModal--yellow:hover {
    background-color: #f8b500;
  }
}

.c-tag_listModal--green.is-active {
  color: #fff;
  background-color: #009fa8;
}

@media screen and (min-width: 768px) {
  .c-tag_listModal--green:hover {
    background-color: #009fa8;
  }
}

.c-tag_listModal--pink.is-active {
  color: #fff;
  background-color: #e85298;
}

@media screen and (min-width: 768px) {
  .c-tag_listModal--pink:hover {
    background-color: #e85298;
  }
}

.c-tag_listModal--orange.is-active {
  color: #fff;
  background-color: #ec6b2e;
}

@media screen and (min-width: 768px) {
  .c-tag_listModal--orange:hover {
    background-color: #ec6b2e;
  }
}

.c-tag_listModal--orange02.is-active {
  color: #fff;
  background-color: #EA5514;
}

@media screen and (min-width: 768px) {
  .c-tag_listModal--orange02:hover {
    background-color: #EA5514;
  }
}

.c-tag_listModal--blue.is-active {
  color: #fff;
  background-color: #4c8dcb;
}

@media screen and (min-width: 768px) {
  .c-tag_listModal--blue:hover {
    background-color: #4c8dcb;
  }
}

.c-tag_detail, .c-tag_detail--yellow, .c-tag_detail--green, .c-tag_detail--pink, .c-tag_detail--orange, .c-tag_detail--orange02, .c-tag_detail--blue {
  margin: 2px 0 2px 4px;
  padding: 4px 10px;
  display: inline-block;
  line-height: 1 !important;
  color: #9FA0A0;
  background-color: #efefef;
  border-radius: 4px;
  cursor: default;
}

@media screen and (min-width: 768px) {
  .c-tag_detail, .c-tag_detail--yellow, .c-tag_detail--green, .c-tag_detail--pink, .c-tag_detail--orange, .c-tag_detail--orange02, .c-tag_detail--blue {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .c-tag_detail:hover, .c-tag_detail--yellow:hover, .c-tag_detail--green:hover, .c-tag_detail--pink:hover, .c-tag_detail--orange:hover, .c-tag_detail--orange02:hover, .c-tag_detail--blue:hover {
    color: #fff;
  }
}

.c-tag_detail--yellow.is-active {
  color: #fff;
  background-color: #f8b500;
}

@media screen and (min-width: 768px) {
  .c-tag_detail--yellow:hover {
    background-color: #f8b500;
  }
}

.c-tag_detail--green.is-active {
  color: #fff;
  background-color: #009fa8;
}

@media screen and (min-width: 768px) {
  .c-tag_detail--green:hover {
    background-color: #009fa8;
  }
}

.c-tag_detail--pink.is-active {
  color: #fff;
  background-color: #e85298;
}

@media screen and (min-width: 768px) {
  .c-tag_detail--pink:hover {
    background-color: #e85298;
  }
}

.c-tag_detail--orange.is-active {
  color: #fff;
  background-color: #ec6b2e;
}

@media screen and (min-width: 768px) {
  .c-tag_detail--orange:hover {
    background-color: #ec6b2e;
  }
}

.c-tag_detail--orange02.is-active {
  color: #fff;
  background-color: #EA5514;
}

@media screen and (min-width: 768px) {
  .c-tag_detail--orange02:hover {
    background-color: #EA5514;
  }
}

.c-tag_detail--blue.is-active {
  color: #fff;
  background-color: #4c8dcb;
}

@media screen and (min-width: 768px) {
  .c-tag_detail--blue:hover {
    background-color: #4c8dcb;
  }
}

.c-tag_detailVer02, .c-tag_detailVer02--yellow, .c-tag_detailVer02--green, .c-tag_detailVer02--pink, .c-tag_detailVer02--orange, .c-tag_detailVer02--orange02, .c-tag_detailVer02--blue {
  margin: 2px 0 2px 4px;
  padding: 4px 10px;
  display: inline-block;
  line-height: 1 !important;
  border-radius: 4px;
  cursor: default;
}

@media screen and (max-width: 767px) {
  .c-tag_detailVer02, .c-tag_detailVer02--yellow, .c-tag_detailVer02--green, .c-tag_detailVer02--pink, .c-tag_detailVer02--orange, .c-tag_detailVer02--orange02, .c-tag_detailVer02--blue {
    margin: 0.3rem .4rem 0.3rem 0;
  }
}

.c-tag_detailVer02--yellow {
  color: #f8b500;
  background-color: #FFFAEB;
}

.c-tag_detailVer02--green {
  color: #009fa8;
  background-color: #edf6f7;
}

.c-tag_detailVer02--pink {
  color: #e85298;
  background-color: #faf2f7;
}

.c-tag_detailVer02--orange {
  color: #ec6b2e;
  background-color: #fdeadd;
}

.c-tag_detailVer02--orange02 {
  color: #EA5514;
  background-color: #FDF1EC;
}

.c-tag_detailVer02--blue {
  color: #4c8dcb;
  background-color: #f1f4fa;
}

.c-tag_close, .c-tag_close--green, .c-tag_close--pink, .c-tag_close--orange, .c-tag_close--orange02, .c-tag_close--blue {
  margin: 8px 14px 5px 0;
  padding: 4px 28px 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1 !important;
  color: #fff;
  background-color: #f8b500;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-tag_close, .c-tag_close--green, .c-tag_close--pink, .c-tag_close--orange, .c-tag_close--orange02, .c-tag_close--blue {
    margin: 5px 14px 5px 0;
    padding: 0.6rem 2rem 0.6rem 1rem;
  }
}

.c-tag_close--green {
  background-color: #009fa8;
}

.c-tag_close--pink {
  background-color: #e85298;
}

.c-tag_close--orange {
  background-color: #ec6b2e;
}

.c-tag_close--orange02 {
  background-color: #EA5514;
}

.c-tag_close--blue {
  background-color: #4c8dcb;
}

.c-tag_closeIco {
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 50%;
  right: 4px;
  z-index: 2;
  transform: translateY(-50%);
  cursor: pointer;
}

.c-tag_closeIco:before, .c-tag_closeIco:after {
  content: '';
  width: 10px;
  height: 2px;
  display: block;
  background-color: #fff;
  border-radius: 50px;
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  z-index: 2;
  transform: translateY(-50%);
}

.c-tag_closeIco:before {
  transform: rotate(-45deg);
}

.c-tag_closeIco:after {
  transform: rotate(45deg);
}

.c-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 2.0833333333333335;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 767px) {
  .c-text {
    font-size: 1rem;
  }
}

.c-text--bold {
  font-weight: bold;
}

.c-text_lead {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-text_lead {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .c-text_lead {
    font-size: 1.3rem;
  }
}

.c-text_middle {
  font-size: 14px;
  line-height: 1.5384615384615385;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-text_middle {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .c-text_middle {
    font-size: 1.2rem;
  }
}

.c-text_small {
  font-size: 11px;
  line-height: 1.4545454545454546;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-text_small {
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .c-text_small {
    font-size: 1rem;
  }
}

.c-text_micro, .c-text_micro--bold {
  font-size: 9px;
  font-weight: 500;
  line-height: 1.6666666666666667;
}

@media screen and (max-width: 767px) {
  .c-text_micro, .c-text_micro--bold {
    font-size: 0.9rem;
  }
}

.c-text_micro--bold {
  font-weight: bold;
}

.c-text_link {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.05em;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-text_link:after {
    content: '';
    width: 0;
    height: 1px;
    top: 100%;
    position: absolute;
    left: 0;
    transition: width 0.3s ease;
  }
  .c-text_link:hover:after {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-text_link {
    font-size: 1rem;
  }
}

.c-text_link--note {
  display: inline-block;
  line-height: 1.4545454545454546;
  letter-spacing: 0.05em;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .c-text_link--note:after {
    content: '';
    width: 0;
    height: 1px;
    top: 100%;
    position: absolute;
    left: 0;
    transition: width 0.3s ease;
  }
  .c-text_link--note:hover:after {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-text_link--note {
    font-size: 1rem;
  }
}

.c-text_color--orange {
  color: #ec6b2e;
}

.c-text_color--orange:after {
  background-color: #ec6b2e;
}

.c-text_color--yellow {
  color: #f8b500;
}

.c-text_color--yellow:after {
  background-color: #f8b500;
}

.c-text_color--white {
  color: #fff;
}

.c-text_color--white:after {
  background-color: #fff;
}

.c-text_color--black {
  color: #3e3a39;
}

.c-text_color--black:after {
  background-color: #3e3a39;
}

.c-upload_list--member {
  display: flex;
  align-items: center;
}

.c-upload_listItem--col {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.c-upload_listItem--col + .c-upload_listItem--col {
  margin: 8px 0 0;
}

.c-upload_listItem--col .c-upload_listIco {
  width: 8px;
  height: 8px;
  margin: 0 0 0 8px;
  display: inline-block;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.c-upload_listItem--col .c-upload_listIco:before, .c-upload_listItem--col .c-upload_listIco:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #3e3a39;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.c-upload_listItem--col .c-upload_listIco:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.c-upload_listItem--col .c-upload_listIco:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.c-upload_listItem--member {
  margin: 0 8px 0 0;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .c-upload_listItem--member .c-upload_listClose {
    transition: opacity 0.3s ease;
  }
  .c-upload_listItem--member:hover .c-upload_listClose {
    opacity: 1;
    z-index: 2;
    pointer-events: auto;
  }
}

.c-upload_listItem--member .c-upload_listClose {
  width: 101%;
  height: 101%;
  background-color: #9FA0A0;
  border: 1px solid #9FA0A0;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
}

.c-upload_listItem--member .c-upload_listClose:before, .c-upload_listItem--member .c-upload_listClose:after {
  content: '';
  width: 8px;
  height: 1px;
  display: block;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.c-upload_listItem--member .c-upload_listClose:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.c-upload_listItem--member .c-upload_listClose:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.c-upload_col {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .c-upload_col {
    flex-wrap: wrap;
  }
}

.c-upload_colItem {
  width: 23%;
}

@media screen and (min-width: 768px) {
  .c-upload_colItem {
    max-width: 180px;
  }
}

@media screen and (max-width: 767px) {
  .c-upload_colItem {
    width: 47%;
  }
  .c-upload_colItem:nth-child(n + 3) {
    margin-top: 1rem;
  }
}

.c-upload_colInner + .c-upload_colInner {
  margin-top: 8px;
}

.p-data {
  width: 100%;
}

.p-dataCont, .p-dataCont--opacity, .p-dataCont--box {
  width: 100%;
}

.p-dataCont--opacity {
  margin-bottom: 20px;
  padding: 20px 0;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  .p-dataCont--opacity {
    padding: 1.5rem;
  }
}

.p-dataCont--box {
  width: 100%;
  padding: 100px 80px 68px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-dataCont--box {
    padding: 6rem 2rem 4rem;
  }
}

@media screen and (min-width: 768px) {
  .p-dataCont_wrap {
    max-inline-size: -webkit-max-content;
    max-inline-size: -moz-max-content;
    max-inline-size: max-content;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.p-dataCont_text {
  font-weight: bold;
  color: #d16a36;
}

@media screen and (min-width: 768px) {
  .p-dataCont_text:after {
    content: "：";
  }
}

@media screen and (max-width: 767px) {
  .p-dataCont_text {
    margin-bottom: 1rem;
  }
}

.p-dataCont_inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .p-dataCont_inner {
    margin: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .p-dataCont_inner {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .p-dataCont_innerBox {
    width: 48%;
  }
}

@media screen and (min-width: 768px) {
  .p-dataCont_innerLine {
    width: 10px;
    height: 1px;
    margin: 0 20px;
    background-color: #878788;
  }
}

@media screen and (max-width: 767px) {
  .p-dataCont_innerLine {
    margin-top: 1rem;
  }
}

.p-dataForm {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-dataForm {
    width: 100%;
    justify-content: space-between;
  }
}

.p-dataForm_head {
  margin-right: 10px;
  line-height: 1;
  color: #878788;
}

@media screen and (max-width: 767px) {
  .p-dataForm_head {
    margin-right: 0.5rem;
  }
}

.p-dataForm_body {
  height: 38px;
}

@media screen and (min-width: 768px) {
  .p-dataForm_body {
    width: 200px;
  }
}

@media screen and (max-width: 767px) {
  .p-dataForm_body {
    flex: 1;
  }
}

.p-dataForm_label {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border: 1px solid #878788;
  border-radius: 5px;
  overflow: hidden;
}

.p-dataForm_label:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.p-dataForm_label:before {
  background-color: #fff;
  z-index: -1;
}

.p-dataForm_input {
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  font-size: inherit;
  text-align: left;
  color: #3e3a39;
  background: none;
  border-radius: 0;
  box-shadow: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (min-width: 768px) {
  .p-dataForm_input {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .p-dataForm_input {
    padding: 0 0.5rem;
    font-size: 1rem;
  }
}

.p-dataForm_input::-webkit-calendar-picker-indicator {
  display: none;
}

.p-dataForm_inputIco {
  width: 18px;
  height: 20px;
  display: block;
  background: url("../img/common/ico_calendar.svg") no-repeat center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 8px;
  z-index: 2;
  transform: translateY(-50%);
}

.p-dataForm_btn {
  width: 120px;
  height: 38px;
}

@media screen and (max-width: 767px) {
  .p-dataForm_btn {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
}

.p-dataForm_btnLabel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #d16a36;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .p-dataForm_btnLabel {
    transition: opacity 0.3s ease;
  }
  .p-dataForm_btnLabel:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 767px) {
  .p-dataForm_btnLabel {
    width: 35%;
  }
}

.p-dataForm_btnItem {
  display: none;
}

.p-dataResult_text {
  width: 170px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background-color: #d16a36;
  border-radius: 0 100px 100px 0;
  position: absolute;
  top: 36px;
  left: 0;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .p-dataResult_text {
    width: 40%;
    top: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-dataBox {
    display: flex;
    justify-content: space-between;
  }
}

.p-dataBox + .p-dataBox {
  margin-top: 60px;
}

.p-dataBox_list {
  width: 33%;
}

@media screen and (min-width: 768px) {
  .p-dataBox_list {
    width: 272px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .p-dataBox_list {
    width: 100%;
  }
}

.p-dataBox_list:first-child {
  padding-left: 0;
}

@media screen and (max-width: 767px) {
  .p-dataBox_list + .p-dataBox_list {
    margin-top: 4rem;
    padding-top: 4rem;
    border-top: 1px solid #f8b500;
  }
}

@media screen and (min-width: 768px) {
  .p-dataBox_list + .p-dataBox_list .p-dataBox_border {
    border-left: 2px solid #f8b500;
  }
}

.p-dataBox_heading {
  padding-bottom: 28px;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .p-dataBox_heading {
    padding-bottom: 1rem;
    font-size: 1.2rem;
  }
}

.p-dataBox_pointList {
  width: 100%;
  height: 20px;
  padding: 0 0 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  color: #fff;
  background: linear-gradient(90deg, #d16a36 0%, #d98a57 100%);
  border-radius: 0 100px 100px 0;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-dataBox_pointList {
    height: 2rem;
    font-size: 1rem;
  }
}

.p-dataBox_pointList + .p-dataBox_pointList {
  margin-top: 10px;
}

.p-dataBox_graph {
  width: 100%;
  height: 260px;
  margin-bottom: 40px;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-dataBox_graph {
    height: 20rem;
    margin-bottom: 0;
  }
}

.p-dataBox_graph svg {
  transform: scale(1.5);
}

.p-dataBox_graph svg text {
  font-size: 9px;
}

.p-dataBox_graph svg rect {
  fill: none;
}

.p-dataBox_border {
  height: 100%;
  padding: 0 24px;
}

@media screen and (max-width: 767px) {
  .p-dataBox_border {
    padding: 2rem 0;
  }
}

.p-dataRank {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.p-dataRank + .p-dataRank {
  margin-top: 16px;
}

.p-dataRank_head {
  width: 30px;
  margin-right: 12px;
}

@media screen and (max-width: 767px) {
  .p-dataRank_head {
    margin-bottom: 4px;
  }
}

.p-dataRank_body {
  flex: 1;
}

.p-dataMember {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.p-dataMember_thumb {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  border-radius: 50%;
  overflow: hidden;
}

.p-dataMember_box {
  flex: 1;
  overflow: hidden;
  text-align: left;
}

.p-dataMember_name, .p-dataMember_name--line {
  width: 100%;
  padding: 0 60px 0 8px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-dataMember_name, .p-dataMember_name--line {
    padding: 0 6rem 0 8px;
    line-height: 1.6;
  }
}

.p-dataMember_name--line:after {
  content: "";
  width: 100vw;
  height: 1px;
  background: url("../img/common/bg_dots.svg") repeat-x left center;
  background-size: 65px;
  position: absolute;
  top: 50%;
  left: 9px;
  z-index: -1;
  transform: translateY(-50%);
}

.p-dataMember_nameInner {
  padding-right: 4px;
  display: inline-block;
  background-color: #fff;
}

.p-dataMember_inner {
  position: relative;
  z-index: 1;
}

.p-dataMember_num, .p-dataMember_num--member, .p-dataMember_num--nice {
  padding-left: 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 20/18;
  text-align: left;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2;
  transform: translateY(-50%);
}

.p-dataMember_num--member {
  width: 40px;
}

.p-dataMember_num--nice {
  width: 52px;
}

/* calendar */
.flatpickr-calendar.animate.open {
  margin-top: 4px;
}

.p-detail {
  width: 100%;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-detail {
    padding: 2rem 0 0;
  }
}

.p-edit {
  width: 100%;
}

.p-edit_cont {
  width: 100%;
  padding: 4% 10%;
  background-color: #fff;
  border-radius: 20px;
}

@media screen and (max-width: 767px) {
  .p-edit_cont {
    padding: 3rem 2rem;
    border-radius: 10px;
  }
}

.p-edit_box + .p-edit_box {
  margin: 32px 0 0;
}

@media screen and (max-width: 767px) {
  .p-edit_box + .p-edit_box {
    margin: 2.5rem 0 0;
  }
}

.p-editInfo_head {
  padding: 0 0 4px;
}

.p-editInfo_inner + .p-editInfo_inner {
  margin: 8px 0 0;
}

.p-editInfo_textarea {
  height: 200px;
}

.p-editBtn {
  width: 100%;
  margin: 36px 0 0;
}

.p-login {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .p-login {
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  .p-login {
    flex-flow: column;
  }
}

.p-login_box {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-login_box {
    width: 100%;
    padding: 5rem 0;
  }
}

.p-login_box:last-child {
  border-radius: 30px 0 0 30px;
}

@media screen and (max-width: 767px) {
  .p-login_box:last-child {
    border-radius: 15px 15px 0 0;
  }
}

.p-login_inner {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .p-login_inner {
    max-width: 500px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .p-login_inner {
    width: 90%;
  }
}

.p-login_heading--h1 {
  width: 100%;
  margin: 0 0 52px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-login_heading--h1 {
    margin: 0 0 24px;
  }
}

@media screen and (max-width: 767px) {
  .p-login_heading--h1 {
    margin: 0 0 2.5rem;
  }
}

.p-login_heading--h1 .p-login_headingSub {
  width: 100%;
  padding: 0 0 16px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.17em;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-login_heading--h1 .p-login_headingSub {
    padding: 0 0 12px;
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .p-login_heading--h1 .p-login_headingSub {
    padding: 0 0 1.5rem;
    font-size: 1.1rem;
  }
}

.p-login_heading--h1 .p-login_headingLogo {
  width: 51%;
  margin: 0 auto;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .p-login_heading--h1 .p-login_headingLogo {
    max-width: 182px;
  }
}

.p-login_heading--h2 {
  width: 100%;
  margin: 0 0 12px;
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  .p-login_heading--h2 {
    margin: 0 0 1rem;
  }
}

.p-login_heading--h2 .p-login_headingLogo {
  width: 110px;
  margin: 0 8px 0 0;
  display: inline-block;
}

.p-login_heading--h2 .p-login_headingInner {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4285714285714286;
  letter-spacing: 0.17em;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .p-login_heading--h2 .p-login_headingInner {
    font-size: 1.1rem;
  }
}

.p-login_pass {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-login_pass {
    margin: 1.5rem 0;
  }
}

.p-loginForm {
  width: 100%;
}

.p-loginForm_box {
  width: 100%;
}

.p-loginForm_box + .p-loginForm_box {
  margin: 20px 0 0;
}

@media screen and (max-width: 767px) {
  .p-loginForm_box + .p-loginForm_box {
    margin: 1.5rem 0 0;
  }
}

.p-pin {
  width: 100%;
  margin: 0 0 72px;
}

@media screen and (max-width: 767px) {
  .p-pin {
    margin: 0 0 4rem;
  }
}

.p-result_heading {
  margin: 0 0 20px;
}

.p-result_cont {
  width: 100%;
  margin: 0 0 80px;
  padding: 20px 36px;
  background-color: #fff;
  border-radius: 20px;
}

@media screen and (max-width: 767px) {
  .p-result_cont {
    margin: 0 0 6rem;
    padding: 2rem 1.5rem;
    border-radius: 10px;
  }
}

.p-result_lead {
  padding: 0 0 12px;
}

.p-resultInfo {
  width: 100%;
  display: flex;
}

@media screen and (max-width: 767px) {
  .p-resultInfo {
    display: block;
  }
}

.p-resultInfo + .p-resultInfo {
  margin: 12px 0 0;
}

@media screen and (max-width: 767px) {
  .p-resultInfo + .p-resultInfo {
    margin: 1.5rem 0 0;
  }
}

.p-resultInfo_head {
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-resultInfo_head {
    width: 100%;
    margin: 0 0 0.2rem;
    display: block;
  }
}

.p-resultInfo_head:after {
  content: ':';
  margin: 0 12px 0 0;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.0833333333333335;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 767px) {
  .p-resultInfo_head:after {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .p-resultInfo_body {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .p-resultInfo_body {
    width: 100%;
  }
}

.p-upload_cont {
  width: 100%;
  padding: 6% 10% 52px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .p-upload_cont {
    margin: 2rem 0 0;
    padding: 3rem 2rem;
  }
}

.p-upload_heading {
  width: 100%;
  margin: 0 0 48px;
}

@media screen and (max-width: 767px) {
  .p-upload_heading {
    margin: 0 0 3rem;
  }
}

.p-upload_box + .p-upload_box {
  margin: 24px 0 0;
}

@media screen and (max-width: 767px) {
  .p-upload_box + .p-upload_box {
    margin: 2rem 0 0;
  }
}

.p-uploadInfo_head {
  margin: 0 0 4px;
}

.p-uploadInfo_body--flex {
  display: flex;
  align-items: center;
}

.p-uploadInfo_textarea {
  width: 100%;
  height: 200px;
}

.p-uploadInfo_textarea--inner {
  width: 100%;
  height: 150px;
}

.p-uploadInfo_inner + .p-uploadInfo_inner {
  margin: 8px 0 0;
}

@media screen and (max-width: 767px) {
  .p-uploadInfo_list {
    max-width: 80%;
    width: 100%;
  }
}

.p-uploadInfo_list + .p-uploadInfo_list {
  margin-left: 24px;
}

.p-uploadInfo_require {
  color: #ec6b2e;
}

.p-uploadRadio {
  width: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-uploadRadio {
    flex-wrap: wrap;
  }
}

.p-uploadRadio + .p-uploadRadio {
  margin-top: 8px;
}

@media screen and (max-width: 767px) {
  .p-uploadRadio + .p-uploadRadio {
    margin-top: .5rem;
  }
}

.p-uploadRadio_list {
  margin: 0 24px 0 0;
}

@media screen and (max-width: 767px) {
  .p-uploadRadio_list {
    margin: 0.2rem 1rem 0 0;
  }
}

.p-uploadBtn {
  width: 100%;
  margin: 52px 0 0;
}

.btn {
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  color: #000;
  font-size: 14px;
  font-family: 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
  background: transparent;
  cursor: pointer;
  border: #000 1px solid;
  border-radius: 3px;
  padding: 5px 10px;
}

.btn--typeA {
  color: #ffffff;
  background: #ff0000;
}

.btn--typeB {
  color: #ffffff;
  background: #0000ff;
}
