.c-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 2.0833333333333335;
  letter-spacing: 0.05em;

  @include sp {
    font-size: 1rem;
  }

  &--bold {
    font-weight: bold;
  }

  &_lead {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.05em;

    @include tab {
      font-size: 14px;
    }

    @include sp {
      font-size: 1.3rem;
    }
  }

  &_middle {
    font-size: 14px;
    line-height: 1.5384615384615385;
    letter-spacing: 0.05em;

    @include tab {
      font-size: 12px;
    }

    @include sp {
      font-size: 1.2rem;
    }
  }

  &_small {
    font-size: 11px;
    line-height: 1.4545454545454546;
    letter-spacing: 0.05em;

    @include tab {
      font-size: 10px;
    }

    @include sp {
      font-size: 1rem;
    }
  }

  &_micro {
    $this: &;
    font-size: 9px;
    font-weight: 500;
    line-height: 1.6666666666666667;

    @include sp {
      font-size: 0.9rem;
    }

    &--bold {
      @extend #{$this};
      font-weight: bold;
    }
  }

  &_link {
    $this: &;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.05em;
    position: relative;
    z-index: 1;
    cursor: pointer;

    @include pc {
      &:after {
        content: '';
        @include hoverLine();
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }
    }

    @include sp {
      font-size: 1rem;
    }

    &--note {
      display: inline-block;
      line-height: 1.4545454545454546;
      letter-spacing: 0.05em;
      position: relative;
      z-index: 1;
      cursor: pointer;

      @include pc {
        &:after {
          content: '';
          @include hoverLine();
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      @include sp {
        font-size: 1rem;
      }
    }
  }

  &_color {
    &--orange {
      color: $orange;

      &:after {
        background-color: $orange;
      }
    }

    &--yellow {
      color: $yellow;

      &:after {
        background-color: $yellow;
      }
    }

    &--white {
      color: $white;

      &:after {
        background-color: $white;
      }
    }

    &--black {
      color: $black;

      &:after {
        background-color: $black;
      }
    }
  }
}
