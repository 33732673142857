.c-heading {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 1.1111111111111112;
  letter-spacing: 0.05em;

  @include tab {
    font-size: 16px;
  }

  @include sp {
    font-size: 1.3rem;
  }

  &_large {
    @extend .c-heading;
    font-size: 20px;
    letter-spacing: 0;

    @include tab {
      font-size: 18px;
    }

    @include sp {
      font-size: 1.3rem;
    }
  }

  &--tl {
    text-align: left;
  }

  &--ta {
    text-align: center;
  }
}
