.p-result {
  &_heading {
    margin: 0 0 20px;
  }

  &_cont {
    width: 100%;
    margin: 0 0 80px;
    padding: 20px 36px;
    background-color: $white;
    border-radius: 20px;

    @include sp {
      margin: 0 0 6rem;
      padding: 2rem 1.5rem;
      border-radius: 10px;
    }
  }

  &_lead {
    padding: 0 0 12px;
  }

  &Info {
    width: 100%;
    display: flex;

    @include sp {
      display: block;
    }

    & + & {
      margin: 12px 0 0;

      @include sp {
        margin: 1.5rem 0 0;
      }
    }

    &_head {
      width: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include sp {
        width: 100%;
        margin: 0 0 0.2rem;
        display: block;
      }

      &:after {
        content: ':';
        margin: 0 12px 0 0;
        display: block;
        font-size: 12px;
        font-weight: 500;
        line-height: 2.0833333333333335;
        letter-spacing: 0.05em;

        @include sp {
          display: none;
        }
      }
    }

    &_body {
      @include pc {
        flex: 1;
        display: flex;
        align-items: center;
      }

      @include sp {
        width: 100%;
      }
    }
  }
}
