.c-bg {
  &Grad {
    &--yellow {
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        width: 100vw;
        height: 100vh;
        background: $gradYellow;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -2;
      }
    }

    &_inner {
      &--yellow {
        background: $gradYellow;
      }
    }
  }
}
