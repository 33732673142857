.c-list {
  &Cat {
    width: 100%;

    &_cont {
      $this: &;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      @include sp {
        display: block;
      }

      & + & {
        @include sp {
          margin-top: 1rem;
        }
      }

      &--inner {
        @extend #{$this};
        margin-bottom: 12px;
        display: block;

        .c-listCat {
          &_cont {
            &Head {
              width: 100%;
              margin-right: 0;
              padding: 0;
            }

            &Body {
              width: 100%;
            }
          }
        }
      }

      &Head {
        width: 135px;
        margin-right: 12px;
        padding: 5px 0;

        @include sp {
          width: 100%;
          margin-right: 0;
          margin-bottom: 0.2rem;
          padding: 0;
        }
      }

      &Body {
        @include pc {
          flex: 1;
        }

        @include sp {
          width: 100%;
        }
      }

      &Unit {
        display: flex;
        flex-wrap: wrap;

        &Item {
          cursor: default !important;
        }
      }
    }
  }
}