.c-profile {
  width: 100%;
  margin: 0 0 80px;

  @include sp {
    margin: 0 0 4rem;
  }

  &_cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    overflow: hidden;
    background-color: $white;

    @include sp {
      display: block;
    }
  }

  &_box {
    &:first-child {
      width: 350px;
      padding: 20px 44px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      background-color: $orange;
      border-radius: 0 20px 20px 0;
      overflow: hidden;

      @include sp {
        width: 100%;
        padding: 3rem 2rem;
        border-radius: 0 0 20px 20px;
      }
    }

    &:last-child {
      padding: 28px 44px;

      @include pc {
        flex: 1;
      }

      @include sp {
        padding: 3rem 2rem;
      }
    }
  }

  &_link {
    width: 100%;
    margin: 28px 0 0;
    text-align: center;

    @include sp {
      margin: 2rem 0 0;
    }
  }

  &_lead {
    margin: 0 0 12px;
  }

  &Info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &_pic {
      width: 80px;
      height: 80px;
      margin: 0 16px 0 0;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    &_box {
      flex: 1;
    }

    &_inner {
      text-align: left;

      & + & {
        margin: 8px 0 0;
      }
    }
  }

  /* PICK UP実績 */
  &Intro {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @include sp {
      height: 2.5rem;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: url("../img/common/bg_profile.png") no-repeat center bottom;
      background-size: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      @include sp {
        background-size: cover;
      }
    }

    &_heading {
      width: 100%;
      position: relative;
      z-index: 2;
    }
  }
  /* end PICK UP実績 */

  /* 実績&データ */
  &Tab {
    width: 100%;
    position: relative;
    z-index: 1;

    &_cont {
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;

      &.is-left {
        background: url("../img/common/bg_tab_left.png") no-repeat left 6px;
        background-size: 100% 70px;

        .c-profile {
          &Tab {
            &_cont {
              &List {
                &:first-child {
                  .c-profileTab_contItem {
                    color: $white;
                    pointer-events: none;
                  }
                }
              }
            }
          }
        }
      }

      &.is-right {
        background: url("../img/common/bg_tab_right.png") no-repeat right 6px;
        background-size: 100% 70px;

        .c-profile {
          &Tab {
            &_cont {
              &List {
                &:last-child {
                  .c-profileTab_contItem {
                    color: $white;
                    pointer-events: none;
                  }
                }
              }
            }
          }
        }
      }

      &List {
        width: 50%;
        height: 100%;

        &:first-child {
          padding-right: 240px;

          @include tab {
            padding-right: 160px;
          }

          @include sp {
            padding-right: 0;
          }

          .c-profileTab_contItem {
            justify-content: flex-end;

            @include sp {
              justify-content: center;
            }
          }
        }

        &:last-child {
          padding-left: 240px;

          @include tab {
            padding-left: 160px;
          }

          @include sp {
            padding-left: 0;
          }

          .c-profileTab_contItem {
            justify-content: flex-start;

            @include sp {
              justify-content: center;
            }
          }
        }
      }

      &Item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        color: $black;

        @include pc {
          transition: opacity $anime;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  /* end 実績&データ */

  &Cont {
    padding: 60px 0 72px;

    @include sp {
      padding: 4rem 0;
    }

    &--color {
      $this: &;
      padding: 48px 0 72px;
      background-color: $yellow;

      @include sp {
        padding: 4rem 0;
      }

      &02 {
        @extend #{$this};
        padding: 72px 0;
        background-color: $yellowDeep;

        @include sp {
          padding: 4rem 0;
        }
      }
    }
  }
}
