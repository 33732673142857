.l-wrap {
  width: 100%;
  margin: 0 auto;

  @include pc {
    max-width: 1040px;
    padding: 0 20px;
  }

  @include sp {
    width: 95%;
  }
}
