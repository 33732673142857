.c-not {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;

  &_cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_circle {
    width: 300px;
    height: 300px;
    margin: 0 30px;
    border: 12px solid red;
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      width: 100%;
      height: 10px;
      background-color: red;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      content: '';
      width: 65%;
      height: 65%;
      background: url('../img/common/logo_ie.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -2;
      transform: translate(-50%, -50%);
    }

    &--sp {
      @extend .c-not_circle;

      @include sp {
        width: 40vw;
        height: 40vw;
        border-width: 8px;
      }

      &:after {
        width: 70%;
        height: 70%;
        background: url('../img/common/img_smartphone.png') no-repeat center;
        background-size: contain;
      }
    }
  }

  &_dame {
    width: 300px;

    @include sp {
      width: 25vw;
    }

    img {
      width: 100%;
    }
  }

  &_link {
    width: auto;
    margin: 32px 0 0;
    display: inline-block;
    text-align: center;

    @include pc {
      transition: opacity $anime;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
