.c-upload {
  &_list {
    &--member {
      display: flex;
      align-items: center;
    }

    &Item {
      &--col {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;

        & + & {
          margin: 8px 0 0;
        }

        .c-upload_listIco {
          width: 8px;
          height: 8px;
          margin: 0 0 0 8px;
          display: inline-block;
          position: relative;
          z-index: 1;
          cursor: pointer;

          &:before,
          &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: $black;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
          }

          &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
          }

          &:after {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }

      &--member {
        margin: 0 8px 0 0;
        position: relative;
        z-index: 1;

        @include pc {
          .c-upload_listClose {
            transition: opacity $anime;
          }

          &:hover {
            .c-upload_listClose {
              opacity: 1;
              z-index: 2;
              pointer-events: auto;
            }
          }
        }

        .c-upload_listClose {
          width: 101%;
          height: 101%;
          background-color: $grayDeep;
          border: 1px solid $grayDeep;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          // z-index: 2;
          opacity: 0;
          cursor: pointer;
          pointer-events: none;

          &:before,
          &:after {
            content: '';
            width: 8px;
            height: 1px;
            display: block;
            background-color: $white;
            border-radius: 4px;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
          }

          &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
          }

          &:after {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }

  &_col {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include sp {
      flex-wrap: wrap;
    }

    &Item {
      width: 23%;
      
      @include pc {
        max-width: 180px;
      }

      @include sp {
        width: 47%;

        &:nth-child(n + 3) {
          margin-top: 1rem;
        }
      }
    }

    &Inner {
      & + & {
        margin-top: 8px;
      }
    }
  }
}
