.c-info {
  width: 100%;
  display: flex;
  align-items: center;

  @include sp {
    display: block;
  }

  &--multi {
    @extend .c-info;
    align-items: flex-start;
  }

  &--search {
    @extend .c-info;
    position: relative;
    z-index: 2;
  }

  &_heading {
    width: 120px;
    margin: 0 20px 0 0;

    @include sp {
      width: 100%;
      margin: 0 0 0.5rem 0;
    }
  }

  &_box {
    flex: 1;
    position: relative;
    z-index: 1;

    &Inner {
      width: 100%;
      position: relative;
      z-index: 3;
    }
  }

  &Focus {
    width: 100%;
    position: relative;
    z-index: 1;

    &.is-view {
      .c-infoFocus {
        &_wrap {
          opacity: 1;
          z-index: 2;
          pointer-events: auto;
        }
      }
    }

    &_wrap {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -9999;
      opacity: 0;
      pointer-events: none;
    }

    &_box {
      padding: 8px 16px;
      background-color: $grayPale;

      @include sp {
        padding: 0.6rem 1.5rem;
      }
    }
  }
}
