.p-login {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include pc {
    height: 100vh;
  }

  @include sp {
    flex-flow: column;
  }

  &_box {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include sp {
      width: 100%;
      padding: 5rem 0;
    }

    &:last-child {
      border-radius: 30px 0 0 30px;

      @include sp {
        border-radius: 15px 15px 0 0;
      }
    }
  }

  &_inner {
    width: 100%;

    @include pc {
      max-width: 500px;
      padding: 0 20px;
    }

    @include sp {
      width: 90%;
    }
  }

  &_heading {
    &--h1 {
      width: 100%;
      margin: 0 0 52px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      @include tab {
        margin: 0 0 24px;
      }

      @include sp {
        margin: 0 0 2.5rem;
      }

      .p-login_heading {
        &Sub {
          width: 100%;
          padding: 0 0 16px;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.17em;
          text-align: center;

          @include tab {
            padding: 0 0 12px;
            font-size: 12px;
          }

          @include sp {
            padding: 0 0 1.5rem;
            font-size: 1.1rem;
          }
        }

        &Logo {
          width: 51%;
          margin: 0 auto;
          display: inline-block;

          @include pc {
            max-width: 182px;
          }
        }
      }
    }

    &--h2 {
      width: 100%;
      margin: 0 0 12px;
      display: flex;
      align-items: flex-end;

      @include sp {
        margin: 0 0 1rem;
      }

      .p-login_heading {
        &Logo {
          width: 110px;
          margin: 0 8px 0 0;
          display: inline-block;
        }

        &Inner {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.4285714285714286;
          letter-spacing: 0.17em;
          color: $white;

          @include sp {
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  &_pass {
    width: 100%;
    margin: 20px 0;
    text-align: center;

    @include sp {
      margin: 1.5rem 0;
    }
  }

  &Form {
    width: 100%;

    &_box {
      width: 100%;

      & + & {
        margin: 20px 0 0;

        @include sp {
          margin: 1.5rem 0 0;
        }
      }
    }
  }
}
