.p-data {
  width: 100%;

  &Cont {
    $this: &;
    width: 100%;

    &--opacity {
      @extend #{$this};
      margin-bottom: 20px;
      padding: 20px 0;
      background-color: rgba($white, 0.7);
      border-radius: 10px;

      @include sp {
        padding: 1.5rem;
      }
    }

    &--box {
      @extend #{$this};
      width: 100%;
      padding: 100px 80px 68px;
      background-color: $white;
      border-radius: 10px;
      position: relative;
      z-index: 1;

      @include sp {
        padding: 6rem 2rem 4rem;
      }
    }

    &_wrap {
      @include pc {
        max-inline-size: -webkit-max-content;
        max-inline-size: -moz-max-content;
        max-inline-size: max-content;
        margin-inline: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &_text {
      font-weight: bold;
      color: $orange03;

      @include pc {
        &:after {
          content: "：";
        }
      }

      @include sp {
        margin-bottom: 1rem;
      }
    }

    &_inner {
      display: flex;
      justify-content: center;
      align-items: center;
      @include pc {
        margin: 0 20px;
      }

      @include sp {
        width: 100%;
        justify-content: space-between;
      }

      &Box {
        @include sp {
          width: 48%;
        }
      }

      &Line {
        @include pc {
          width: 10px;
          height: 1px;
          margin: 0 20px;
          background-color: $grayDeep02;
        }

        @include sp {
          margin-top: 1rem;
        }
      }
    }
  }

  &Form {
    display: flex;
    align-items: center;

    @include sp {
      width: 100%;
      justify-content: space-between;
    }

    &_head {
      margin-right: 10px;
      line-height: 1;
      color: $grayDeep02;

      @include sp {
        margin-right: 0.5rem;
      }
    }

    &_body {
      height: 38px;

      @include pc {
        width: 200px;
      }

      @include sp {
        flex: 1;
      }
    }

    &_label {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      z-index: 1;
      cursor: pointer;
      position: relative;
      z-index: 1;
      border: 1px solid $grayDeep02;
      border-radius: 5px;
      overflow: hidden;

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
      }

      &:before {
        background-color: $white;
        z-index: -1;
      }
    }

    &_input {
      height: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      font-size: inherit;
      text-align: left;
      color: $black;
      background: none;
      border-radius: 0;
      box-shadow: none;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      @include pc {
        width: 100%;
      }

      @include sp {
        padding: 0 0.5rem;
        font-size: 1rem;
      }

      &::-webkit-calendar-picker-indicator {
        display: none;
      }

      &Ico {
        width: 18px;
        height: 20px;
        display: block;
        background: url("../img/common/ico_calendar.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 8px;
        z-index: 2;
        transform: translateY(-50%);
      }
    }

    &_btn {
      width: 120px;
      height: 38px;

      @include sp {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
      }

      &Label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $orange03;
        border-radius: 5px;
        cursor: pointer;

        @include pc {
          transition: opacity $anime;

          &:hover {
            opacity: 0.7;
          }
        }

        @include sp {
          width: 35%;
        }
      }

      &Item {
        display: none;
      }
    }
  }

  &Result {
    &_text {
      width: 170px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      text-align: center;
      color: $white;
      background-color: $orange03;
      border-radius: 0 100px 100px 0;
      position: absolute;
      top: 36px;
      left: 0;
      z-index: 2;

      @include sp {
        width: 40%;
        top: 2.5rem;
      }
    }
  }

  &Box {
    @include pc {
      display: flex;
      justify-content: space-between;
    }

    & + & {
      margin-top: 60px;
    }

    &_list {
      $this: &;
      width: 33%;

      @include pc {
        width: 272px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        text-align: center;
      }

      @include sp {
        width: 100%;
      }

      &:first-child {
        padding-left: 0;
      }

      & + & {
        @include sp {
          margin-top: 4rem;
          padding-top: 4rem;
          border-top: 1px solid $yellow;
        }

        .p-dataBox_border {
          @include pc {
            border-left: 2px solid $yellow;
          }
        }
      }
    }

    &_heading {
      padding-bottom: 28px;
      font-size: 14px;
      font-weight: bold;

      @include sp {
        padding-bottom: 1rem;
        font-size: 1.2rem;
      }
    }

    &_point {
      &List {
        width: 100%;
        height: 20px;
        padding: 0 0 0 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 10px;
        color: $white;
        background: linear-gradient(90deg, rgba(209, 106, 54, 1) 0%, rgba(217, 138, 87, 1) 100%);
        border-radius: 0 100px 100px 0;
        position: relative;
        z-index: 1;

        @include sp {
          height: 2rem;
          font-size: 1rem;
        }

        & + & {
          margin-top: 10px;
        }
      }
    }

    &_graph {
      width: 100%;
      height: 260px;
      margin-bottom: 40px;
      padding: 0 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include sp {
        height: 20rem;
        margin-bottom: 0;
      }

      svg {
        transform: scale(1.5);

        text {
          font-size: 9px;
        }

        rect {
          fill: none;
        }
      }
    }

    &_border {
      height: 100%;
      padding: 0 24px;

      @include sp {
        padding: 2rem 0;
      }
    }
  }

  &Rank {
    width: 100%;
    display: flex;
    align-items: flex-end;

    & + & {
      margin-top: 16px;
    }

    &_head {
      width: 30px;
      margin-right: 12px;

      @include sp {
        margin-bottom: 4px;
      }
    }

    &_body {
      flex: 1;
    }
  }

  &Member {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &_thumb {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      border-radius: 50%;
      overflow: hidden;
    }

    &_box {
      flex: 1;
      overflow: hidden;
      text-align: left;
    }

    &_name {
      $this: &;
      width: 100%;
      padding: 0 60px 0 8px;
      display: inline-block;
      font-size: 12px;
      font-weight: bold;
      position: relative;
      z-index: 1;

      @include sp {
        padding: 0 6rem 0 8px;
        line-height: 1.6;
      }

      &--line {
        @extend #{$this};

        &:after {
          content: "";
          width: 100vw;
          height: 1px;
          background: url("../img/common/bg_dots.svg") repeat-x left center;
          background-size: 65px;
          position: absolute;
          top: 50%;
          left: 9px;
          z-index: -1;
          transform: translateY(-50%);
        }
      }

      &Inner {
        padding-right: 4px;
        display: inline-block;
        background-color: $white;
      }
    }

    &_inner {
      position: relative;
      z-index: 1;
    }

    &_num {
      $this: &;
      padding-left: 8px;
      font-size: 12px;
      font-weight: bold;
      line-height: 20/18;
      text-align: left;
      background-color: $white;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 2;
      transform: translateY(-50%);

      &--member {
        @extend #{$this};
        width: 40px;
      }

      &--nice {
        @extend #{$this};
        width: 52px;
      }
    }
  }
}

/* calendar */
.flatpickr-calendar.animate.open {
  margin-top: 4px;
}
