.c-person {
  width: 100%;
  padding: 0 8px 0 0;
  display: flex;
  flex-wrap: wrap;

  &--detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include sp {
      justify-content: flex-start;
    }
  }

  &_list {
    width: 15px;
    margin: 0 4px 0 0;
    position: relative;
    z-index: 1;

    @include sp {
      width: 2.2rem;
      height: 100%;
      margin: 0 1rem 0 0;
    }

    &--detail {
      width: 20px;
      margin: 2px 0 2px 8px;

      @include sp {
        width: 2.5rem;
        margin: .5rem 1rem .5rem 0;
      }
    }
  }
}

.is-hide {
  .c-person {
    height: 100%;
    // overflow: hidden;
    position: relative;
    z-index: 1;

    &:before {
      content: '･･･';
      display: block;
      font-size: 8px;
      color: $grayDeep;
      letter-spacing: -0.2em;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 3;
      transform: translateY(-50%);

      @include sp {
        font-size: 1rem;
        letter-spacing: 0;
        right: 0.5rem;
      }
    }

    &:after {
      content: '';
      width: 12px;
      height: 100%;
      background-color: $white;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;

      @include sp {
        width: 1.6rem;
        right: 0.5rem;
      }
    }
  }
}
