.c-job {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--modal {
    @extend .c-job;
    justify-content: flex-start;

    @include sp {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .c-job_list {
      cursor: pointer;
      pointer-events: auto;
      color: $grayDeep;
      background-color: $grayPale03;

      @include pc {
        transition: color $anime, background-color $anime;

        &:hover {
          color: $white;
          background-color: $orange;
        }
      }

      @include sp {
        width: 46%;
        margin: 0;

        &:nth-child(n + 3) {
          margin: 1rem 0 0;
        }
      }
    }
  }

  &--detail {
    @extend .c-job;

    @include sp {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .c-job_list {
      width: 24%;
      border-radius: 10px;

      @include pc {
        max-width: 130px;
        height: 50px;
      }

      @include sp {
        width: 48%;
        height: 2.4rem;

        &:nth-child(n + 3) {
          margin: 1rem 0 0;
        }
      }

      &:after {
        @include pc {
          width: 80%;
          height: 16px;
        }
      }

      &--graphic {
        &:after {
          @include pc {
            max-width: 113px;
          }
        }
      }

      &--web {
        &:after {
          @include pc {
            max-width: 57px;
          }
        }
      }

      &--edit {
        &:after {
          @include pc {
            max-width: 55px;
          }
        }
      }

      &--movie {
        &:after {
          @include pc {
            max-width: 78px;
          }
        }
      }

      &--event {
        &:after {
          @include pc {
            max-width: 78px;
          }
        }
      }
    }
  }

  &_list {
    width: 22%;
    height: 20px;
    background-color: $orangePale;
    border-radius: 4px;
    position: relative;
    z-index: 1;

    @include pc {
      max-width: 50px;
    }

    @include sp {
      width: 18%;
    }

    &.is-active {
      background-color: $orange;
    }

    &:after {
      content: '';
      width: 100%;
      height: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);

      @include sp {
        height: 0.6rem;
      }
    }

    &--graphic {
      &:after {
        background: url('../img/common/job_graphic.svg') no-repeat center;
        background-size: contain;

        @include pc {
          max-width: 46px;
        }
      }
    }

    &--web {
      &:after {
        background: url('../img/common/job_web.svg') no-repeat center;
        background-size: contain;

        @include pc {
          max-width: 23px;
        }
      }
    }

    &--edit {
      &:after {
        background: url('../img/common/job_edit.svg') no-repeat center;
        background-size: contain;

        @include pc {
          max-width: 22px;
        }
      }
    }

    &--movie {
      &:after {
        background: url('../img/common/job_movie.svg') no-repeat center;
        background-size: contain;

        @include pc {
          max-width: 32px;
        }
      }
    }

    &--event {
      &:after {
        background: url('../img/common/job_event.svg') no-repeat center;
        background-size: contain;

        @include pc {
          max-width: 32px;
        }
      }
    }

    &Modal {
      @extend .c-job_list;
      margin: 0 14px 0 0;

      &--graphic {
        @extend .c-job_listModal;

        &:after {
          background: url('../img/common/job_graphic.svg') no-repeat center;
          background-size: contain;

          @include pc {
            max-width: 46px;
          }
        }
      }

      &--web {
        @extend .c-job_listModal;

        &:after {
          background: url('../img/common/job_web.svg') no-repeat center;
          background-size: contain;

          @include pc {
            max-width: 23px;
          }
        }
      }

      &--edit {
        @extend .c-job_listModal;

        &:after {
          background: url('../img/common/job_edit.svg') no-repeat center;
          background-size: contain;

          @include pc {
            max-width: 22px;
          }
        }
      }

      &--movie {
        @extend .c-job_listModal;

        &:after {
          background: url('../img/common/job_movie.svg') no-repeat center;
          background-size: contain;

          @include pc {
            max-width: 32px;
          }
        }
      }

      &--event {
        @extend .c-job_listModal;

        &:after {
          background: url('../img/common/job_event.svg') no-repeat center;
          background-size: contain;

          @include pc {
            max-width: 32px;
          }
        }
      }
    }
  }
}
