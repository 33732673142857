html {
  @include sp {
    font-size: calc(100vw / 32);
  }
}

body {
  min-height: 100vh;
  font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', 'メイリオ', sans-serif;
  color: $black;
  position: relative;
  z-index: 2;

  @include sp {
    transition: transform $anime;
  }

  &.is-fixed {
    overflow: hidden;
  }

  &.is-move {
    @include sp {
      transform: translateX(-90%);
      overflow: hidden;
    }
  }
}

main {
  width: 100%;
  height: 100%;
  padding: 130px 0;
  display: block;

  @include sp {
    padding: 9rem 0 7rem;
  }

  &.is-login {
    padding: 0;

    @include sp {
      padding: 0;
    }
  }
}

img {
  width: 100%;
  display: block;
}

.is-pop {
  position: relative !important;
  z-index: 10 !important;
}

.pc {
  @include pc {
    display: block;
  }

  @include sp {
    display: none;
  }
}

.sp {
  @include pc {
    display: none;
  }

  @include sp {
    display: block;
  }
}
