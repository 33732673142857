.c-collision {
  width: 22vw;
  position: absolute;
  animation: 6s linear infinite rotation;

  @include pc {
    max-width: 472px;
  }

  &--01 {
    @extend .c-collision;
    will-change: left, top;
  }

  &--02 {
    @extend .c-collision;
    will-change: left, bottom;
  }

  &--03 {
    @extend .c-collision;
    will-change: right, top;
  }

  &Cont {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
