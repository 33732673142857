.p-upload {
  &_cont {
    width: 100%;
    padding: 6% 10% 52px;
    background-color: $white;
    border-radius: 20px;
    overflow: hidden;

    @include sp {
      margin: 2rem 0 0;
      padding: 3rem 2rem;
    }
  }

  &_heading {
    width: 100%;
    margin: 0 0 48px;

    @include sp {
      margin: 0 0 3rem;
    }
  }

  &_box {
    & + & {
      margin: 24px 0 0;

      @include sp {
        margin: 2rem 0 0;
      }
    }
  }

  &Info {
    &_head {
      margin: 0 0 4px;
    }

    &_body {
      &--flex {
        display: flex;
        align-items: center;
      }
    }

    &_textarea {
      width: 100%;
      height: 200px;

      &--inner {
        width: 100%;
        height: 150px;
      }
    }

    &_inner {
      & + & {
        margin: 8px 0 0;
      }
    }

    &_list {
      @include sp {
        max-width: 80%;
        width: 100%;
      }

      & + & {
        margin-left: 24px;
      }
    }

    &_require {
      color: $orange;
    }
  }

  &Radio {
    width: 100%;
    display: flex;
    align-items: center;

    @include sp {
      flex-wrap: wrap;
    }

    & + & {
      margin-top: 8px;

      @include sp {
        margin-top: .5rem;
      }
    }

    &_list {
      margin: 0 24px 0 0;

      @include sp {
        margin: 0.2rem 1rem 0 0;
      }
    }
  }

  &Btn {
    width: 100%;
    margin: 52px 0 0;
  }
}
