.c-pop {
  min-width: 120px;
  padding: 12px;
  background-color: $orange;
  border-radius: 10px 10px 10px 8px;
  position: absolute;
  bottom: calc(100% + 20px);
  left: 0;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: opacity $anime;

  @include sp {
    display: none;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 0;
    border-color: $orange transparent transparent transparent;
    position: absolute;
    top: calc(100% - 8px);
    left: 0;
    z-index: 2;
  }

  &--profile {
    @extend .c-pop;
    padding: 2px 12px;
    bottom: 70%;
  }

  &.is-view {
    @include pc {
      opacity: 1;
      // pointer-events: auto;
    }
  }

  &_list {
    & + & {
      margin: 6px 0 0;
    }
  }
}
