.c-ico {
  width: 100%;
  display: flex;

  &_list {
    margin: 0 12px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;

    @include pc {
      &:hover {
        .c-ico_item {
          &--good {
            background: url('../img/common/ico_good_orange.svg') no-repeat center;
            background-size: contain;
          }

          &--comment {
            background: url('../img/common/ico_comment_orange.svg') no-repeat center;
            background-size: contain;
          }
        }

        .c-ico_num {
          color: $orange;
        }
      }
    }
  }

  &_item {
    width: 25px;
    height: 25px;
    margin: 0 2px 0 0;
    display: block;

    @include pc {
      transition: background $anime;
    }

    @include sp {
      width: 2.2rem;
      height: 2.2rem;
      margin: 0 0.2rem 0 0;
    }

    &--good {
      @extend .c-ico_item;
      background: url('../img/common/ico_good.svg') no-repeat center;
      background-size: contain;
    }

    &--comment {
      @extend .c-ico_item;
      background: url('../img/common/ico_comment.svg') no-repeat center;
      background-size: contain;
    }
  }

  &_num {
    @include pc {
      transition: color $anime;
    }
  }
}
