// foundation
@import 'foundation/init';
// libs(あれば)
// layout
@import 'layout/init';
// object
@import 'object/init';

.btn {
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  color: #000;
  font-size: 14px;
  font-family: 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
  background: transparent;
  cursor: pointer;
  border: #000 1px solid;
  border-radius: 3px;
  padding: 5px 10px;

  &--typeA {
    color: #ffffff;
    background: #ff0000;
  }
  &--typeB {
    color: #ffffff;
    background: #0000ff;
  }
}
