.p-edit {
  width: 100%;

  &_cont {
    width: 100%;
    padding: 4% 10%;
    background-color: $white;
    border-radius: 20px;

    @include sp {
      padding: 3rem 2rem;
      border-radius: 10px;
    }
  }

  &_box {
    & + & {
      margin: 32px 0 0;

      @include sp {
        margin: 2.5rem 0 0;
      }
    }
  }

  &Info {
    &_head {
      padding: 0 0 4px;
    }

    &_inner {
      & + & {
        margin: 8px 0 0;
      }
    }

    &_textarea {
      height: 200px;
    }
  }

  &Btn {
    width: 100%;
    margin: 36px 0 0;
  }
}
