.c-form {
  &_input {
    $this: &;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.15em;
    line-height: 1.2307692307692308;
    color: $black;
    background-color: $grayPale;
    border: none;
    -webkit-appearance: none;

    @include sp {
      padding: 1rem;
      font-size: 1.1rem;
    }

    &::-webkit-input-placeholder {
      color: $grayDeep;
    }

    &::-moz-placeholder {
      color: $grayDeep;
    }

    &:-ms-input-placeholder {
      color: $grayDeep;
    }

    &::-ms-input-placeholder {
      color: $grayDeep;
    }

    &::placeholder {
      color: $grayDeep;
    }

    &:focus {
      outline: none;
    }

    &--default {
      border-radius: 10px;
    }

    &--rounded {
      border-radius: 100px;

      &:focus {
        outline: none;
      }
    }

    &--inner {
      @extend #{$this};
      padding: 8px;
      font-size: 10px;

      @include sp {
        padding: .6rem;
        font-size: .9rem;
      }
    }
  }

  &_textarea {
    @extend .c-form_input;
    height: 100%;
    resize: none;
  }

  &File {
    $this: &;
    width: 250px;
    padding: 15px 0;
    display: block;
    text-align: center;
    color: $white;
    background-color: $yellow;
    border: none;
    box-shadow: none;
    -webkit-appearance: none;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    cursor: pointer;

    @include pc {
      &:after {
        content: '';
        @include opacity;
      }

      &:hover {
        &:after {
          opacity: 0.3;
        }
      }
    }

    @include sp {
      width: 100%;
      padding: 1.5rem 0;
    }

    &--inner {
      @extend #{$this};
      width: 100%;
      max-width: 150px;
      padding: 10px 0;

      @include sp {
        max-width: 100%;
      }
    }

    &_input {
      display: none;
    }

    &_item {
      display: none;
    }

    &_name {
      margin: 8px 0;
      display: inline-block;
      font-size: 13px;
    }
  }

  &Radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_input {
      display: none;

      &:checked + span {
        background-color: $orange03;
        
        &:after {
          opacity: 1;
        }
      }
    }

    &_ico {
      width: 14px;
      height: 14px;
      margin: 0 6px 0 0;
      box-sizing: border-box;
      display: block;
      background-color: $white;
      border: 1px solid $grayDeep;
      border-radius: 50%;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        width: 6px !important;
        height: 3px !important;
        display: block;
        background:none;
        border-radius: 0%;
        position: absolute;
        top: calc(50% - 1px) !important;
        left: 50% !important;
        z-index: 2;
        transform: translate(-50%,-50%) rotate(-45deg) !important;
        border-bottom: 2px solid $white !important;
        border-left: 2px solid $white !important;
        border-right: none !important;
        opacity: 0;
      }
    }

    &_text {
      display: inline-block;
    }
  }

  &Check {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_input {
      display: none;

      &:checked + span {
        &:after {
          opacity: 1;
        }
      }
    }

    &_ico {
      width: 14px;
      height: 14px;
      margin: 0 6px 0 0;
      box-sizing: border-box;
      display: block;
      background-color: $white;
      border: 1px solid $grayDeep;
      border-radius: 50%;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        width: 9px;
        height: 9px;
        display: block;
        background-color: $orange;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }

    &_text {
      display: inline-block;
    }
  }
  &_delivery_from{
    width: 190px;
  }
}
