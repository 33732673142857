.c-modal {
  &Overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9998;
    pointer-events: none;
    transition: opacity $anime;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: $gradYellow;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.is-view {
      pointer-events: auto;

      &:after {
        opacity: 1;
      }
    }

    &--transparent {
      @extend .c-modalOverlay;
      inset: 0px;

      &.is-view {
        &:after {
          opacity: 0.75;
        }
      }
    }

    &_wrap {
      position: absolute;
      display: block;
      overflow-x: hidden;
      overflow-y: auto;
      opacity: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
    }
  }

  &Close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    cursor: pointer;

    @include sp {
      width: 2rem;
      height: 2rem;
      top: 1.5rem;
      right: 1.5rem;
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: $grayDeep;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &Small {
    width: 100%;
    padding: 80px 0;
    background-color: $white;
    border-radius: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity $anime;

    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }

    @include pc {
      max-width: 580px;
    }

    @include sp {
      width: 90%;
      padding: 5rem 0;
    }

    &_intro {
      margin: 0 0 32px;
      text-align: center;

      @include sp {
        margin: 0 0 2rem;
      }
    }

    &_lead {
      padding: 0 0 16px;

      @include sp {
        padding: 0 0 1rem;
      }
    }

    &_box {
      width: 360px;
      margin: 0 auto;

      @include sp {
        width: 85%;
      }

      &Item {
        width: 100%;

        & + & {
          margin: 20px 0 0;

          @include sp {
            margin: 1rem 0 0;
          }
        }
      }
    }

    &_btn {
      margin: 40px 0 0;

      @include sp {
        margin: 2.5rem 0 0;
      }
    }
  }

  &Main {
    width: 100%;
    padding: 48px 0 40px;
    background-color: rgba($white, 0.8);
    border-radius: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity $anime;

    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }

    @include pc {
      max-width: 800px;
    }

    @include sp {
      width: 95%;
      max-height: 90vh;
      padding: 3rem 0;
    }

    &--smart {
      @extend .c-modalMain;
      margin: 12% auto 0;
      position: absolute;
      top: 0;
      transform: translateX(-50%);

      @include pc {
        max-width: 620px;
      }

      @include sp {
        max-height: none;
        margin: 8rem auto 0;
      }
    }

    &_heading {
      margin: 0 0 20px;

      @include sp {
        margin: 0 0 1.5rem;
      }
    }

    &_cont {
      width: 100%;
      height: 300px;
      margin: 0 auto 24px;
      padding: 24px 10px;
      background: $white;
      border-radius: 20px;

      @include pc {
        max-width: 600px;
      }

      @include sp {
        width: 90%;
        height: 50vh;
        margin: 0 auto 2rem;
        padding: 2rem 1rem;
      }

      &--smart {
        @extend .c-modalMain_cont;
        height: auto;
        background: none;
        padding: 0;
        border-radius: 0;

        @include pc {
          max-width: 480px;
        }

        @include sp {
          height: auto;
          padding: 0;
        }
      }

      &--possible {
        @extend .c-modalMain_cont;
        max-height: 300px;
        height: auto;
      }
    }

    &_scroll {
      width: 100%;
      height: 100%;
      padding: 0 10px;
      position: relative;
    }

    &_box {
      margin: 8px 0 0;
      padding: 8px 0 0;
      border-top: 1px solid $grayPale;

      @include sp {
        margin: 1rem 0 0;
        padding: 1rem 0 0;
      }
    }
  }

  &Pic {
    width: 90%;
    height: 80%;
    padding: 3%;
    border-radius: 30px;
    background-color: $white;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity $anime;

    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }

    &_cont {
      width: 100%;
      height: 100%;
    }

    &_box {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
      }
    }
  }
}
