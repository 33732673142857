.c-summary {
  &Cont {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include sp {
      display: block;
    }

    & + & {
      margin-top: 16px;

      @include sp {
        margin-top: 2rem;
      }
    }

    &_head {
      width: 132px;
      height: 50px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.1em;

      @include pc {
        margin-right: 12px;
      }

      @include sp {
        width: 100%;
        height: auto;
        margin-bottom: 12px;
        font-size: .9rem;
      }
    }

    &_body {
      padding: 14px 16px;
      border: 1px solid $grayDeep;
      border-radius: 10px;

      @include pc {
        flex: 1;
      }

      @include sp {
        width: 100%;
        padding: .5rem 1rem;
      }
    }

    &_text {
      font-size: 13px;
      line-height: 1.8461538461538463;
      color: $blackPale;

      @include sp {
        font-size: .8rem;
      }
    }

    &_link {
      @extend .c-summaryCont_text;

      @include pc {
        transition: opacity .3s ease, color .3s ease;

        &:hover {
          opacity: .7;
          color: $yellow;
        }
      }
    }
  }
}