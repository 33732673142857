.c-tag {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &--top {
    @extend .c-tag;
  }

  &--view {
    @include sp {
      height: auto !important;
    }

    .c-tag_list {
      cursor: default;
    }
  }

  &--detail {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include sp {
      display: block;
    }
  }

  &_list {
    margin: 2px 4px 2px 0;
    padding: 4px 10px;
    display: inline-block;
    line-height: 1 !important;
    color: $grayDeep;
    background-color: $grayPale03;
    border-radius: 4px;
    cursor: pointer;

    @include pc {
      transition: color $anime, background-color $anime;

      &:hover {
        color: $white;
      }
    }

    &--yellow {
      @extend .c-tag_list;

      &.is-active {
        color: $white;
        background-color: $yellow;
      }

      @include pc {
        &:hover {
          background-color: $yellow;
        }
      }
    }

    &--green {
      @extend .c-tag_list;

      &.is-active {
        color: $white;
        background-color: $green;
      }

      @include pc {
        &:hover {
          background-color: $green;
        }
      }
    }

    &--pink {
      @extend .c-tag_list;

      &.is-active {
        color: $white;
        background-color: $pink;
      }

      @include pc {
        &:hover {
          background-color: $pink;
        }
      }
    }

    &--orange {
      @extend .c-tag_list;

      &.is-active {
        color: $white;
        background-color: $orange;
      }

      @include pc {
        &:hover {
          background-color: $orange;
        }
      }
    }

    &--orange02 {
      @extend .c-tag_list;

      &.is-active {
        color: $white;
        background-color: $orange02;
      }

      @include pc {
        &:hover {
          background-color: $orange02;
        }
      }
    }

    &--blue {
      @extend .c-tag_list;

      &.is-active {
        color: $white;
        background-color: $blue;
      }

      @include pc {
        &:hover {
          background-color: $blue;
        }
      }
    }

    &Modal {
      @extend .c-tag_list;
      margin: 5px 14px 5px 0;

      &--yellow {
        @extend .c-tag_listModal;

        &.is-active {
          color: $white;
          background-color: $yellow;
        }

        @include pc {
          &:hover {
            background-color: $yellow;
          }
        }
      }

      &--green {
        @extend .c-tag_listModal;

        &.is-active {
          color: $white;
          background-color: $green;
        }

        @include pc {
          &:hover {
            background-color: $green;
          }
        }
      }

      &--pink {
        @extend .c-tag_listModal;

        &.is-active {
          color: $white;
          background-color: $pink;
        }

        @include pc {
          &:hover {
            background-color: $pink;
          }
        }
      }

      &--orange {
        @extend .c-tag_listModal;

        &.is-active {
          color: $white;
          background-color: $orange;
        }

        @include pc {
          &:hover {
            background-color: $orange;
          }
        }
      }

      &--orange02 {
        @extend .c-tag_listModal;

        &.is-active {
          color: $white;
          background-color: $orange02;
        }

        @include pc {
          &:hover {
            background-color: $orange02;
          }
        }
      }

      &--blue {
        @extend .c-tag_listModal;

        &.is-active {
          color: $white;
          background-color: $blue;
        }

        @include pc {
          &:hover {
            background-color: $blue;
          }
        }
      }
    }
  }

  &_detail {
    margin: 2px 0 2px 4px;
    padding: 4px 10px;
    display: inline-block;
    line-height: 1 !important;
    color: $grayDeep;
    background-color: $grayPale03;
    border-radius: 4px;
    cursor: default;

    @include pc {
      transition: color $anime, background-color $anime;

      &:hover {
        color: $white;
      }
    }

    &--yellow {
      @extend .c-tag_detail;

      &.is-active {
        color: $white;
        background-color: $yellow;
      }

      @include pc {
        &:hover {
          background-color: $yellow;
        }
      }
    }

    &--green {
      @extend .c-tag_detail;

      &.is-active {
        color: $white;
        background-color: $green;
      }

      @include pc {
        &:hover {
          background-color: $green;
        }
      }
    }

    &--pink {
      @extend .c-tag_detail;

      &.is-active {
        color: $white;
        background-color: $pink;
      }

      @include pc {
        &:hover {
          background-color: $pink;
        }
      }
    }

    &--orange {
      @extend .c-tag_detail;

      &.is-active {
        color: $white;
        background-color: $orange;
      }

      @include pc {
        &:hover {
          background-color: $orange;
        }
      }
    }

    &--orange02 {
      @extend .c-tag_detail;

      &.is-active {
        color: $white;
        background-color: $orange02;
      }

      @include pc {
        &:hover {
          background-color: $orange02;
        }
      }
    }

    &--blue {
      @extend .c-tag_detail;

      &.is-active {
        color: $white;
        background-color: $blue;
      }

      @include pc {
        &:hover {
          background-color: $blue;
        }
      }
    }

    &Ver02 {
      $this: &;
      margin: 2px 0 2px 4px;
      padding: 4px 10px;
      display: inline-block;
      line-height: 1 !important;
      border-radius: 4px;
      cursor: default;

      @include sp {
        margin: 0.3rem .4rem 0.3rem 0;
      }
  
      &--yellow {
        @extend #{$this};
        color: $yellow;
        background-color: $yellowPale;
      }
  
      &--green {
        @extend #{$this};
        color: $green;
        background-color: $greenPale;
      }
  
      &--pink {
        @extend #{$this};
        color: $pink;
        background-color: $pinkPale;
      }
  
      &--orange {
        @extend #{$this};
        color: $orange;
        background-color: $orangePale;
      }

      &--orange02 {
        @extend #{$this};
        color: $orange02;
        background-color: $orangePale02;
      }
  
      &--blue {
        @extend #{$this};
        color: $blue;
        background-color: $bluePale;
      }
    }
  }

  &_close {
    margin: 8px 14px 5px 0;
    padding: 4px 28px 4px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1 !important;
    color: $white;
    background-color: $yellow;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    @include sp {
      margin: 5px 14px 5px 0;
      padding: 0.6rem 2rem 0.6rem 1rem;
    }

    &--green {
      @extend .c-tag_close;
      background-color: $green;
    }

    &--pink {
      @extend .c-tag_close;
      background-color: $pink;
    }

    &--orange {
      @extend .c-tag_close;
      background-color: $orange;
    }

    &--orange02 {
      @extend .c-tag_close;
      background-color: $orange02;
    }

    &--blue {
      @extend .c-tag_close;
      background-color: $blue;
    }

    &Ico {
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 50%;
      right: 4px;
      z-index: 2;
      transform: translateY(-50%);
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        width: 10px;
        height: 2px;
        display: block;
        background-color: $white;
        border-radius: 50px;
        position: absolute;
        top: calc(50% - 1px);
        right: 0;
        z-index: 2;
        transform: translateY(-50%);
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }
  }
}
