.c-slider {
  .swiper {
    &-container {
      width: 100%;
      padding: 0 60px;

      @include sp {
        padding: 0;
      }
    }

    &-button {
      &-next,
      &-prev {
        width: 30px;
        height: 30px;
        background-color: $grayPale;
        border-radius: 50%;

        @include pc {
          transition: background-color $anime;

          &:before {
            transition: border-color $anime;
          }

          &:hover {
            background-color: $yellow;
          }
        }

        @include sp {
          width: 2.4rem;
          height: 2.4rem;
        }

        &:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &:after {
          display: none;
        }
      }

      &-next {
        @include sp {
          right: 0;
        }

        &:before {
          left: calc(50% + 2px);
          border-width: 7.5px 0 7.5px 12px;
          border-color: transparent transparent transparent $yellow;
        }

        @include pc {
          &:hover {
            &:before {
              border-color: transparent transparent transparent $white;
            }
          }
        }
      }

      &-prev {
        @include sp {
          left: 0;
        }

        &:before {
          left: calc(50% - 2px);
          border-width: 7.5px 12px 7.5px 0;
          border-color: transparent $yellow transparent transparent;
        }

        @include pc {
          &:hover {
            &:before {
              border-color: transparent $white transparent transparent;
            }
          }
        }
      }
    }
  }

  &Thumbs {
    .swiper {
      &-wrapper {
        justify-content: space-between;
        flex-wrap: wrap;
        transform: none !important;
        transition: none !important;
        border-radius: 0 !important;

        @include pc {
          &:before,
          &:after {
            content: '';
            width: 23%;
          }

          &:before {
            order: 1;
          }

          &:after {
            order: 2;
          }
        }

        @include sp {
          &:after {
            content: '';
            width: 32%;
            display: block;
          }
        }
      }

      &-slide {
        width: 23% !important;
        margin: 0 !important;
        border: 4px solid transparent !important;
        border-radius: 16px;
        overflow: hidden;
        transition: border-color $anime !important;
        cursor: pointer;

        @include pc {
          max-width: 160px !important;

          &:nth-child(n + 5) {
            margin: 20px 0 0 !important;
          }

          &:hover {
            border-color: $orange !important;
          }
        }

        @include sp {
          width: 32% !important;
          border-radius: 8px;
          border-width: 2px !important;

          &:nth-child(n + 4) {
            margin: 0.5rem 0 0 !important;
          }
        }

        &-thumb {
          &-active {
            border-color: $orange !important;
          }
        }
      }
    }
  }
}
