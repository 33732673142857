.c-intro {
  &_text {
    &--work {
      height: 1.2em;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  &Item {
    width: 100%;

    &_list {
      width: 100%;
      padding: 12px 20px;
      background-color: $white;

      & + & {
        margin: 4px 0 0;
      }
    }
  }

  &Cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include sp {
      height: 100%;
    }

    &--name {
      @extend .c-introCont;
      justify-content: flex-end;
      position: relative;
      z-index: 1;

      @include sp {
        justify-content: space-between;
        padding: 1rem 0;
      }
    }

    &--comment {
      @extend .c-introCont;
      align-items: flex-start;
      position: relative;
      z-index: 1;

      @include sp {
        justify-content: space-between;
      }

      & + & {
        margin: 12px 0 0;
        padding: 12px 0 0;
        border-top: 1px dashed $grayDeep;
      }
    }

    &--reply {
      @extend .c-introCont--comment;
      margin: 0 0 24px;
      padding: 28px 3%;

      @include sp {
        margin: 0 0 2rem;
      }

      .c-introCont_edit {
        overflow: hidden;

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }

    &--detail {
      @extend .c-introCont;
      max-width: 100%;
      width: auto;
      padding-bottom: 40px;
      display: inline-flex;
      position: relative;
      z-index: 1;

      @include sp {
        padding-bottom: 0.5rem;
      }
    }

    &--center {
      @extend .c-introCont;
      align-items: center;
      position: relative;
      z-index: 1;

      @include sp {
        justify-content: space-between;
      }
    }

    &--message {
      @extend .c-introCont;
      align-items: flex-start;
    }

    &_link {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      z-index: 1;

      @include pc {
        &:after {
          content: "";
          @include opacity();
        }

        &:hover {
          &:after {
            opacity: 0.3;
          }
        }
      }
    }

    &_pic {
      position: relative;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: $aqua;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }

      &--name {
        @extend .c-introCont_pic;
        width: 30px;
        height: 30px;
        margin: 0 8px 0 0;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include sp {
          width: 4.5rem;
          height: 4.5rem;
          margin: 0 1rem 0 0;
        }
      }

      &--work {
        @extend .c-introCont_pic;
        width: 52px;
        height: 52px;
        margin: 0 8px 0 0;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include sp {
          width: 4.5rem;
          height: 4.5rem;
          margin: 0 1rem 0 0;
        }
      }

      &--message {
        @extend .c-introCont_pic;
        width: 40px;
        height: 40px;
        margin: 0 20px 0 0;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include sp {
          width: 3rem;
          height: 3rem;
          margin: 0 1.5rem 0 0;
        }
      }

      &--small {
        @extend .c-introCont_pic;
        width: 15px;
        height: 15px;
        margin: 0;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include sp {
          width: 2.2rem;
          height: 2.2rem;
          margin: 0;
        }
      }

      &--large {
        @extend .c-introCont_pic--work;
        width: 76px;
        height: 76px;
      }

      &--comment {
        @extend .c-introCont_pic--small;
        margin: 0 4px 0 0;

        @include sp {
          margin: 0 0.5rem 0 0;
        }
      }

      &--add {
        @extend .c-introCont_pic--small;
        width: 20px;
        height: 20px;
        margin: 0 4px 0 0;

        @include sp {
          width: 2.5rem;
          height: 2.5rem;
          margin: 0 0.5rem 0 0;
        }
      }

      &--detail {
        @extend .c-introCont_pic--small;
        width: 20px;
        height: 20px;
        margin: 0;

        @include sp {
          width: 2.5rem;
          height: 2.5rem;
          margin: 0;
        }
      }

      &--form {
        @extend .c-introCont_pic--small;
        width: 25px;
        height: 25px;
        margin: 0;

        @include sp {
          width: 2.5rem;
          height: 2.5rem;
          margin: 0;
        }
      }
    }

    &_box {
      display: flex;
      flex-flow: column;
      justify-content: center;
      flex: 1;

      &--comment {
        @extend .c-introCont_box;
        // height: 3em;
        justify-content: flex-start;
        flex: 1;
        // overflow: hidden;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;

        @include sp {
          // height: 4.2rem;
          padding: 0.4rem 0 0;
        }
      }

      &--message {
        @extend .c-introCont_box;
        padding: 12px 0 0;

        @include sp {
          padding: 0.6rem 0 0;
        }

        .c-introCont_textarea {
          padding: 8px;
          font-size: 14px;
          line-height: 1.5384615384615385;
          letter-spacing: 0.05em;

          @include tab {
            font-size: 12px;
          }

          @include sp {
            font-size: 1.2rem;
          }
        }

        .c-introCont_btn {
          &Item {
            font-size: 13px;

            @include sp {
              font-size: 1.2rem;
            }
          }
        }

        & + .c-introConfig {
          width: 60px;

          .c-introConfig_list {
            width: 24px;
            height: 24px;
          }

          .c-introConfig_ico {
            &--edit {
              width: 13px;
              height: 13px;
            }

            &--trash {
              width: 11px;
              height: 13px;
            }
          }
        }
      }
    }

    &_text {
      &--name {
        line-height: 1.75;
      }
    }

    &_edit {
      width: 100%;
    }

    &_textarea {
      width: 100%;
      padding: 4px;
      box-sizing: border-box;
      font-size: 11px;
      line-height: 1.4545454545454546;
      letter-spacing: 0.05em;
      -webkit-appearance: none;
      resize: none;
      border: 1px solid $black;
      border-radius: 0;

      @include tab {
        font-size: 10px;
      }

      @include sp {
        font-size: 1rem;
      }
    }

    &_btn {
      width: 100%;
      margin: 8px 0 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &.is-hide {
        display: none;
      }

      &Item {
        padding: 4px 12px;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        background-color: $white;
        border-radius: 100px;
        cursor: pointer;

        @include pc {
          transition: color $anime, background-color $anime;

          &:hover {
            color: $white;
            background-color: $orange;
          }
        }

        & + & {
          margin: 0 0 0 8px;

          @include sp {
            margin: 0 0 0 1rem;
          }
        }

        &--cancel {
          @extend .c-introCont_btnItem;
          color: $black;
        }

        &--complete {
          @extend .c-introCont_btnItem;
          color: $orange;
        }
      }
    }
  }

  &Config {
    width: 40px;
    margin: 0 0 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include sp {
      width: 20%;
      margin: 0 0 0 0.5rem;
      padding: 0.3rem 0 0;
    }

    &_list {
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border-radius: 50%;
      cursor: pointer;

      @include pc {
        transition: background-color $anime;

        &:hover {
          background-color: $orange;

          .c-introConfig_ico {
            &--edit {
              background: url("../img/common/ico_comment_edit_white.svg") no-repeat center;
              background-size: contain;
            }

            &--trash {
              background: url("../img/common/ico_comment_trash_white.svg") no-repeat center;
              background-size: contain;
            }
          }
        }
      }

      @include sp {
        width: 2rem;
        height: 2rem;
        background-color: $orange;

        .c-introConfig_ico {
          &--edit {
            background: url("../img/common/ico_comment_edit_white.svg") no-repeat center;
            background-size: contain;
          }

          &--trash {
            background: url("../img/common/ico_comment_trash_white.svg") no-repeat center;
            background-size: contain;
          }
        }
      }

      &:first-child {
        &.is-active {
          pointer-events: none;
        }
      }
    }

    &_ico {
      &--edit {
        width: 10px;
        height: 10px;
        background: url("../img/common/ico_comment_edit.svg") no-repeat center;
        background-size: contain;

        @include sp {
          width: 0.9rem;
          height: 0.9rem;
        }
      }

      &--trash {
        width: 8px;
        height: 10px;
        background: url("../img/common/ico_comment_trash.svg") no-repeat center;
        background-size: contain;

        @include sp {
          width: 0.7rem;
          height: 0.9rem;
        }
      }
    }
  }
}
