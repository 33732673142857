.c-scroll {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: $grayPale;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 90px;
    background-color: $yellow;
    border-radius: 50px;
  }
}
